import React, { createContext, useContext, useState, useEffect } from "react";

/**
 * Interface representing an item in the shopping cart
 */
interface CartItem {
  /** Name of the item */
  name: string;
  /** Price of the item */
  price: number;
  /** Quantity of the item */
  quantity: number;
}

/**
 * Interface defining the cart context functionality
 */
interface CartContextType {
  /** Array of items in the cart */
  items: CartItem[];
  /** Function to add an item to the cart */
  addItem: (item: CartItem) => void;
  /** Function to remove an item from the cart */
  removeItem: (index: number) => void;
  /** Function to update item quantity */
  updateQuantity: (index: number, quantity: number) => void;
  /** Function to clear all items from the cart */
  clearCart: () => void;
}

const CartContext = createContext<CartContextType | undefined>(undefined);

/**
 * Provider component that manages shopping cart state.
 * Handles cart operations and persists cart data in localStorage.
 *
 * @param props - The provider props
 * @returns A context provider for cart functionality
 */
export const CartProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [items, setItems] = useState<CartItem[]>(() => {
    const savedItems = localStorage.getItem("cartItems");
    return savedItems ? JSON.parse(savedItems) : [];
  });

  useEffect(() => {
    localStorage.setItem("cartItems", JSON.stringify(items));
  }, [items]);

  const addItem = (newItem: CartItem) => {
    console.log("Adding item:", newItem); // Log the item being added
    setItems((prevItems) => {
      const existingItemIndex = prevItems.findIndex(
        (item) => item.name === newItem.name,
      );

      if (existingItemIndex >= 0) {
        const updatedItems = [...prevItems];
        updatedItems[existingItemIndex].quantity += newItem.quantity;
        console.log("Updated items:", updatedItems); // Log updated items
        return updatedItems;
      }

      return [...prevItems, newItem];
    });
  };

  const removeItem = (index: number) => {
    setItems((prevItems) => prevItems.filter((_, i) => i !== index));
  };

  const updateQuantity = (index: number, quantity: number) => {
    setItems((prevItems) => {
      const updatedItems = [...prevItems];
      updatedItems[index].quantity = quantity;
      return updatedItems;
    });
  };

  const clearCart = () => {
    setItems([]);
  };

  return (
    <CartContext.Provider
      value={{
        items,
        addItem,
        removeItem,
        updateQuantity,
        clearCart,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => {
  const context = useContext(CartContext);
  if (context === undefined) {
    throw new Error("useCart must be used within a CartProvider");
  }
  return context;
};
