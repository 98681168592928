import React, { useState, useEffect, useRef } from "react";
import {
  generateFullPeriodicTable,
  generateNursingTerms,
  generateScienceTerms,
  generateCountingSvg,
} from "../utils/RandomGenerators";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  DroppableProvided,
  DraggableProvided,
} from "@hello-pangea/dnd";

/**
 * Props for the EditCollectionModal component
 */
interface EditCollectionModalProps {
  /** Controls whether the modal is visible */
  isOpen: boolean;
  /** Callback function to close the modal */
  onClose: () => void;
  /** Name of the collection being edited */
  collectionName: string;
  /** Array of items in the collection */
  items: { 
    name: string; 
    id?: number; 
    svg?: string; 
    count?: number 
  }[];
  /** Callback function to save changes to the collection */
  onSave: (
    items: { name: string; id?: number; svg?: string; count?: number }[],
    collectionName: string,
    isPublic: boolean,
  ) => Promise<void>;
  /** Type of collection (e.g., "custom", "scienceTerms", etc.) */
  type: string;
  /** Boolean indicating if the collection is public */
  isPublic: boolean;
}

/**
 * Modal component for editing collection details and items.
 * Supports drag-and-drop reordering, various item types (custom, science terms, etc.),
 * and different visualization options based on collection type.
 *
 * @param props - The component props
 * @returns A modal for editing collection contents and settings
 */
const EditCollectionModal: React.FC<EditCollectionModalProps> = ({
  isOpen,
  onClose,
  collectionName,
  items,
  onSave,
  type,
  isPublic,
}) => {
  console.log("Collection type:", type); // Use it in your logic
  const [editedItems, setEditedItems] = useState<
    { name: string; id?: number; svg?: string; count?: number }[]
  >([]);
  const [editedCollectionName, setEditedCollectionName] =
    useState(collectionName);
  const [isCollectionPublic, setIsCollectionPublic] = useState(isPublic); // State for checkbox
  const modalRef = useRef<HTMLDivElement>(null);
  const [selectedItemType, setSelectedItemType] = useState<string>("custom"); // New state for item type selection
  const [availableTerms, setAvailableTerms] = useState<string[]>([]); // State for available terms
  const [selectedTerm, setSelectedTerm] = useState<string>(""); // State for the selected term
  const [customItemName, setCustomItemName] = useState<string>(""); // State for custom item name
  const [dotColor, setDotColor] = useState<string>("blue");
  const [dotShape, setDotShape] = useState<string>("circle");

  const predefinedItems = {
    scienceTerms: generateScienceTerms(20),
    periodicTable: generateFullPeriodicTable(),
    nursingTerms: generateNursingTerms(20),
    numberSense: [],
  };

  const decodeSvgData = (data: string) => {
    try {
      if (data.startsWith("data:image/svg+xml;base64,")) {
        return decodeURIComponent(atob(data.split(",")[1]));
      } else if (data.startsWith("data:image/svg+xml,")) {
        return decodeURIComponent(data.split(",")[1]);
      } else if (data.startsWith("<svg")) {
        return data;
      } else if (data.startsWith("data:image/")) {
        // Return the original data URL for non-SVG images
        return data;
      }
      return null;
    } catch (e) {
      console.error("Error decoding data:", e);
      return null;
    }
  };

  useEffect(() => {
    const processedItems = items.map((item) => {
      // For items with SVG data
      if (item.svg) {
        return {
          name: item.name,
          id: item.id,
          svg: item.svg,
          count: item.count || 1,
        };
      }
      // For all other items
      return {
        name: typeof item.name === "object" ? JSON.stringify(item.name) : String(item.name),
        id: item.id,
      };
    });
    setEditedItems(processedItems);
  }, [items]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, onClose]);

  useEffect(() => {
    // Update available terms based on selected item type
    if (selectedItemType === "scienceTerms") {
      setAvailableTerms(generateScienceTerms(20));
    } else if (selectedItemType === "nursingTerms") {
      setAvailableTerms(generateNursingTerms(20));
    } else if (selectedItemType === "periodicTable") {
      setAvailableTerms(predefinedItems.periodicTable);
    } else {
      setAvailableTerms([]);
    }
    setSelectedTerm("");
  }, [selectedItemType]);

  const handleAddItem = () => {
    if (selectedItemType === "custom") {
      setEditedItems([
        { name: customItemName || "New Custom Item" },
        ...editedItems,
      ]);
      setCustomItemName("");
    } else if (selectedItemType === "numberSense") {
      const newCount = 1;
      const newSvg = generateCountingSvg(newCount, dotColor, dotShape);
      const newItem = {
        name: `Number Sense Image (Count: ${newCount})`,
        svg: newSvg,
        count: newCount,
      };
      setEditedItems([newItem, ...editedItems]);
    } else {
      setEditedItems([{ name: selectedTerm }, ...editedItems]);
      setSelectedTerm("");
    }
  };

  const handleRemoveItem = (index: number) => {
    const newItems = editedItems.filter((_, i) => i !== index);
    setEditedItems(newItems);
  };

  const handleSave = () => {
    const processedItems = editedItems.map((item) => {
      // Check for svg property instead of relying on type
      if (item.svg) {
        return {
          name: item.name,
          svg: item.svg,
          count: item.count || 1,
          id: item.id,
        };
      }
      return {
        name: item.name,
        id: item.id,
      };
    });

    onSave(processedItems, editedCollectionName, isCollectionPublic);
    onClose();
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleAddItem(); // Call the function to add the item
    }
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const items = Array.from(editedItems);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setEditedItems(items);
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/70">
      <div
        ref={modalRef}
        className="relative flex max-h-[90vh] w-full max-w-4xl flex-col items-center overflow-y-auto rounded-lg p-4 shadow-lg"
        style={{
          backgroundColor: "#FFFFFF",
          color: "#000000",
        }}
      >
        <button
          type="button"
          onClick={onClose}
          className="absolute right-2 top-2 rounded-lg bg-red-500 px-3 py-2 text-sm text-white transition duration-300 hover:bg-red-600"
        >
          X
        </button>
        <h1 className="mb-4 text-xl font-bold sm:mb-6 sm:text-2xl">
          Edit Collection
        </h1>
        <div className="mb-4 flex flex-col items-center justify-center">
          <label htmlFor="collectionName" className="mb-2 block">
            Collection Name
          </label>
          <input
            type="text"
            id="collectionName"
            className="w-full rounded border border-black bg-white p-2 text-center text-black"
            value={editedCollectionName}
            onChange={(e) => setEditedCollectionName(e.target.value)}
            title="Collection Name"
          />
        </div>
        <div className="mb-4">
          <label className="flex items-center">
            <input
              type="checkbox"
              checked={isCollectionPublic}
              onChange={() => setIsCollectionPublic(!isCollectionPublic)}
              className="mr-2"
            />
            Make Collection Public
          </label>
        </div>
        <div className="mb-4 flex flex-col items-center justify-center">
          <label htmlFor="itemTypeSelect" className="mb-2 block">
            Select Item Type
          </label>
          <select
            id="itemTypeSelect"
            value={selectedItemType}
            onChange={(e) => {
              setSelectedItemType(e.target.value);
              setCustomItemName(""); // Reset custom item name when changing type
              setSelectedTerm(""); // Reset selected term when changing type
            }}
            className="mb-4 rounded border p-2"
          >
            <option value="custom">Custom Item</option>
            <option value="scienceTerms">Science Terms</option>
            <option value="periodicTable">Periodic Table</option>
            <option value="nursingTerms">Nursing Terms</option>
            <option value="numberSense">Number Sense</option>
          </select>

          {availableTerms.length > 0 && (
            <div className="mb-4 flex flex-col items-center justify-center">
              <label htmlFor="termSelect" className="mb-2 block">
                Select a Term
              </label>
              <select
                id="termSelect"
                value={selectedTerm}
                onChange={(e) => setSelectedTerm(e.target.value)}
                className="mb-4 rounded border p-2"
              >
                <option value="">Select a term</option>
                {availableTerms.map((term, index) => (
                  <option key={index} value={term}>
                    {term}
                  </option>
                ))}
              </select>
            </div>
          )}

          {selectedItemType === "custom" && (
            <div className="mb-4 flex flex-col items-center justify-center">
              <label htmlFor="customItemInput" className="mb-2 block">
                Custom Item Name
              </label>
              <input
                type="text"
                id="customItemInput"
                value={customItemName}
                onChange={(e) => setCustomItemName(e.target.value)}
                onKeyDown={handleKeyDown}
                className="w-full rounded border p-2 text-center"
                placeholder="Enter custom item name"
              />
            </div>
          )}

          {selectedItemType === "numberSense" && (
            <div className="mb-4 flex flex-col items-center justify-center">
              <div className="mb-4 grid grid-cols-2 gap-4">
                <div>
                  <label htmlFor="dotColor" className="mb-2 block">
                    Dot Color
                  </label>
                  <select
                    id="dotColor"
                    value={dotColor}
                    onChange={(e) => setDotColor(e.target.value)}
                    className="w-full rounded border p-2"
                  >
                    <option value="blue">Blue</option>
                    <option value="red">Red</option>
                    <option value="green">Green</option>
                    <option value="black">Black</option>
                  </select>
                </div>
                <div>
                  <label htmlFor="dotShape" className="mb-2 block">
                    Dot Shape
                  </label>
                  <select
                    id="dotShape"
                    value={dotShape}
                    onChange={(e) => setDotShape(e.target.value)}
                    className="w-full rounded border p-2"
                  >
                    <option value="circle">Circle</option>
                    <option value="square">Square</option>
                    <option value="triangle">Triangle</option>
                  </select>
                </div>
              </div>
            </div>
          )}

          <button
            type="button"
            className="flex size-10 items-center justify-center rounded-full border border-black bg-green-500 text-2xl text-white transition duration-300 hover:bg-green-600"
            onClick={handleAddItem}
          >
            +
          </button>
        </div>
        <div className="mt-6 w-full">
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="items" direction="horizontal">
              {(provided: DroppableProvided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="grid grid-cols-2 gap-4 md:grid-cols-3"
                >
                  {editedItems.map((item, index) => (
                    <Draggable
                      key={index.toString()}
                      draggableId={index.toString()}
                      index={index}
                    >
                      {(provided: DraggableProvided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="relative rounded-md border border-black p-2 hover:cursor-move"
                        >
                          <div className="flex flex-col items-center">
                            {item.svg && (
                              <>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: decodeSvgData(item.svg) || "",
                                  }}
                                  className="mb-2 h-auto w-full"
                                  style={{ minHeight: "100px" }}
                                />
                                <div className="mt-2 w-full">
                                  <input
                                    title="Count"
                                    placeholder="Enter count"
                                    type="number"
                                    value={item.count || 1}
                                    onChange={(e) => {
                                      const newItems = [...editedItems];
                                      const newCount = parseInt(e.target.value) || 1;
                                      const newSvg = generateCountingSvg(
                                        newCount,
                                        dotColor,
                                        dotShape
                                      );
                                      newItems[index] = {
                                        ...item,
                                        name: `Number Sense Image (Count: ${newCount})`,
                                        count: newCount,
                                        svg: newSvg,
                                      };
                                      setEditedItems(newItems);
                                    }}
                                    min="1"
                                    className="w-full rounded border border-gray-300 px-2 py-1 text-center"
                                  />
                                  <div className="mt-2 flex justify-between">
                                    <button
                                      onClick={() => {
                                        const newItems = [...editedItems];
                                        const newSvg = generateCountingSvg(
                                          item.count || 1,
                                          dotColor,
                                          dotShape
                                        );
                                        newItems[index] = {
                                          ...item,
                                          svg: newSvg,
                                        };
                                        setEditedItems(newItems);
                                      }}
                                      className="rounded bg-blue-500 px-2 py-1 text-white"
                                    >
                                      Update Style
                                    </button>
                                  </div>
                                </div>
                              </>
                            )}
                            {!item.svg && !item.name.startsWith("data:image") && (
                              <div className="flex flex-col items-center">
                                <p className="text-center break-words max-w-full">
                                  {typeof item.name === "string" ? item.name : JSON.stringify(item.name)}
                                </p>
                              </div>
                            )}
                          </div>
                          <button
                            type="button"
                            onClick={() => handleRemoveItem(index)}
                            className="absolute -right-2 -top-2 rounded-lg bg-red-500 px-3 py-2 text-sm text-white transition duration-300 hover:bg-red-600"
                          >
                            X
                          </button>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
        <div className="mt-6 flex w-full justify-around">
          <button
            type="button"
            className="rounded border border-black bg-green-500 px-4 py-2 text-white transition duration-300 hover:bg-green-600"
            onClick={handleSave}
          >
            Save Collection
          </button>
          <button
            type="button"
            className="rounded border border-black bg-red-500 px-4 py-2 text-white transition duration-300 hover:bg-red-600"
            onClick={onClose}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditCollectionModal;
