import { periodicTable } from "./periodicTable";
import { nursingTermsData } from "./nursingTerms";
import { scienceTermsData } from "./scienceTerms";

/**
 * Generates an array of random letters (both uppercase and lowercase)
 * @param quantity - Number of letters to generate
 * @returns Array of random letters
 */
export const generateRandomLetters = (quantity: number): string[] => {
  const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  const result: string[] = [];
  for (let i = 0; i < quantity; i++) {
    const randomLetter = letters.charAt(
      Math.floor(Math.random() * letters.length),
    );
    result.push(randomLetter);
  }
  return result;
};

/**
 * Generates an array of random uppercase letters, attempting to avoid duplicates
 * @param quantity - Number of letters to generate
 * @returns Array of random uppercase letters
 */
export const generateRandomUppercaseLetters = (quantity: number): string[] => {
  const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const uniqueLetters = Array.from(letters);
  const result: string[] = [];

  for (let i = 0; i < Math.min(quantity, uniqueLetters.length); i++) {
    const randomIndex = Math.floor(Math.random() * uniqueLetters.length);
    result.push(uniqueLetters[randomIndex]);
    uniqueLetters.splice(randomIndex, 1);
  }

  if (quantity > result.length) {
    const remainingCount = quantity - result.length;
    for (let i = 0; i < remainingCount; i++) {
      const randomIndex = Math.floor(Math.random() * letters.length);
      result.push(letters[randomIndex]);
    }
  }

  return result;
};

/**
 * Generates an array of random lowercase letters, attempting to avoid duplicates
 * @param quantity - Number of letters to generate
 * @returns Array of random lowercase letters
 */
export const generateRandomLowercaseLetters = (quantity: number): string[] => {
  const letters = "abcdefghijklmnopqrstuvwxyz";
  const uniqueLetters = Array.from(letters);
  const result: string[] = [];

  for (let i = 0; i < Math.min(quantity, uniqueLetters.length); i++) {
    const randomIndex = Math.floor(Math.random() * uniqueLetters.length);
    result.push(uniqueLetters[randomIndex]);
    uniqueLetters.splice(randomIndex, 1);
  }

  if (quantity > result.length) {
    const remainingCount = quantity - result.length;
    for (let i = 0; i < remainingCount; i++) {
      const randomIndex = Math.floor(Math.random() * letters.length);
      result.push(letters[randomIndex]);
    }
  }

  return result;
};

/**
 * Generates an array of random mixed case letters, attempting to avoid duplicates
 * @param quantity - Number of letters to generate
 * @returns Array of random mixed case letters
 */
export const generateRandomMixedCaseLetters = (quantity: number): string[] => {
  const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  const uniqueLetters = Array.from(letters);
  const result: string[] = [];

  for (let i = 0; i < Math.min(quantity, uniqueLetters.length); i++) {
    const randomIndex = Math.floor(Math.random() * uniqueLetters.length);
    result.push(uniqueLetters[randomIndex]);
    uniqueLetters.splice(randomIndex, 1);
  }

  if (quantity > result.length) {
    const remainingCount = quantity - result.length;
    for (let i = 0; i < remainingCount; i++) {
      const randomIndex = Math.floor(Math.random() * letters.length);
      result.push(letters[randomIndex]);
    }
  }

  return result;
};

/**
 * Generates an array of random numbers as strings
 * @param quantity - Number of numbers to generate
 * @returns Array of random numbers as strings
 */
export const generateRandomNumbers = (quantity: number): string[] => {
  const uniqueNumbers = Array.from({ length: 100 }, (_, i) =>
    (i + 1).toString(),
  );
  const result: string[] = [];

  for (let i = 0; i < Math.min(quantity, uniqueNumbers.length); i++) {
    const randomIndex = Math.floor(Math.random() * uniqueNumbers.length);
    result.push(uniqueNumbers[randomIndex]);
    uniqueNumbers.splice(randomIndex, 1);
  }

  if (quantity > result.length) {
    const remainingCount = quantity - result.length;
    for (let i = 0; i < remainingCount; i++) {
      const randomIndex = Math.floor(Math.random() * 100) + 1; // Random numbers from 1 to 100
      result.push(randomIndex.toString());
    }
  }

  return result;
};

/**
 * Generates the full alphabet in both uppercase and lowercase
 * @returns Array containing all letters A-Z and a-z
 */
export const generateFullAlphabet = (): string[] => {
  const uppercase = Array.from({ length: 26 }, (_, i) =>
    String.fromCharCode(65 + i),
  ); // A-Z
  const lowercase = Array.from({ length: 26 }, (_, i) =>
    String.fromCharCode(97 + i),
  ); // a-z
  return [...uppercase, ...lowercase];
};

/**
 * Generates numbers from 1 to 100 as strings
 * @returns Array of numbers 1-100 as strings
 */
export const generateNumbersOneToHundred = (): string[] => {
  return Array.from({ length: 100 }, (_, i) => (i + 1).toString());
};

/**
 * Generates numbers from 1 to 20 as strings
 * @returns Array of numbers 1-20 as strings
 */
export const generateNumbersOneToTwenty = (): string[] => {
  return Array.from({ length: 20 }, (_, i) => (i + 1).toString());
};

/** Mapping of operation types to their display symbols */
const operationSymbol = {
  multiplication: "×",
  addition: "+",
  subtraction: "−",
  division: "÷",
} as const;

/**
 * Generates random math problems with solutions
 * @param count - Number of problems to generate
 * @param operation - Type of mathematical operation
 * @returns Array of problems with their answers
 */
export const generateMathProblems = (
  count: number,
  operation: keyof typeof operationSymbol,
): { problem: string; answer: number }[] => {
  const problems: { problem: string; answer: number }[] = [];
  for (let i = 0; i < count; i++) {
    const num1 = Math.floor(Math.random() * 10) + 1;
    const num2 = Math.floor(Math.random() * 10) + 1;
    let answer: number;
    switch (operation) {
      case "addition":
        answer = num1 + num2;
        break;
      case "subtraction":
        answer = num1 - num2;
        break;
      case "multiplication":
        answer = num1 * num2;
        break;
      case "division":
        answer = Math.round((num1 / num2) * 100) / 100;
        break;
      default:
        throw new Error(`Unsupported operation: ${operation}`);
    }
    problems.push({
      problem: `${num1} ${operationSymbol[operation]} ${num2}`,
      answer: answer,
    });
  }
  return problems;
};

/**
 * Generates SVG images for number sense exercises
 * @param count - Number of shapes to generate
 * @param color - Color of the shapes
 * @param shape - Type of shape to generate
 * @returns Object containing SVG data and count
 */
export function generateNumberSenseImages(
  count: number,
  color: string = "blue",
  shape: string = "circle",
): { url: string; svg: string; count: number } {
  const svg = generateCountingSvg(count, color, shape);
  return {
    url: svg,
    svg: svg,
    count: count,
  };
}

export function generateCountingSvg(
  count: number,
  color: string = "blue",
  shape: string = "circle",
  position: string = "random",
): string {
  const gridSize = 5; // 5x5 grid
  const cellSize = 40;
  const svgSize = gridSize * cellSize;

  let positions: { x: number; y: number }[];
  if (position === "random") {
    positions = getRandomPositions(count, gridSize);
  } else {
    positions = getPositionsByPreference(count, gridSize, position);
  }

  const svgContent = `
    <svg xmlns="http://www.w3.org/2000/svg" width="${svgSize}" height="${svgSize}" viewBox="0 0 ${svgSize} ${svgSize}">
      ${positions
        .map((pos) => {
          const x = pos.x * cellSize + cellSize / 2;
          const y = pos.y * cellSize + cellSize / 2;
          let shapeElement = "";
          switch (shape) {
            case "circle":
              shapeElement = `<circle cx="${x}" cy="${y}" r="${cellSize / 3}" fill="${color}" />`;
              break;
            case "square":
              shapeElement = `<rect x="${x - cellSize / 3}" y="${y - cellSize / 3}" width="${(cellSize * 2) / 3}" height="${(cellSize * 2) / 3}" fill="${color}" />`;
              break;
            case "triangle": {
              const size = (cellSize * 2) / 3;
              shapeElement = `<polygon points="${x},${y - size / 2} ${x - size / 2},${y + size / 2} ${x + size / 2},${y + size / 2}" fill="${color}" />`;
              break;
            }
          }
          return shapeElement;
        })
        .join("")}
    </svg>
  `;
  return `data:image/svg+xml,${encodeURIComponent(svgContent)}`;
}

/**
 * Generates random positions on a grid based on specified pattern
 * @param count - Number of positions to generate
 * @param gridSize - Size of the grid (width/height)
 * @param pattern - Pattern to follow ('random', 'diagonal', 'horizontal', 'vertical')
 * @returns Array of position objects with x,y coordinates
 */
export function getRandomPositions(
  count: number,
  gridSize: number,
): { x: number; y: number }[] {
  const positions: { x: number; y: number }[] = [];
  const allPositions = Array.from({ length: gridSize * gridSize }, (_, i) => ({
    x: i % gridSize,
    y: Math.floor(i / gridSize),
  }));

  for (let i = 0; i < count; i++) {
    if (allPositions.length === 0) break;
    const index = Math.floor(Math.random() * allPositions.length);
    positions.push(allPositions[index]);
    allPositions.splice(index, 1);
  }

  return positions;
}

function getPositionsByPreference(
  count: number,
  gridSize: number,
  preference: string,
): { x: number; y: number }[] {
  const positions: { x: number; y: number }[] = [];
  let startX: number, startY: number;

  switch (preference) {
    case "1": // Top Left
      startX = 0;
      startY = 0;
      break;
    case "2": // Top Right
      startX = gridSize - 1;
      startY = 0;
      break;
    case "3": // Center
      startX = Math.floor(gridSize / 2);
      startY = Math.floor(gridSize / 2);
      break;
    case "4": // Bottom Left
      startX = 0;
      startY = gridSize - 1;
      break;
    case "5": // Bottom Right
      startX = gridSize - 1;
      startY = gridSize - 1;
      break;
    default:
      return getRandomPositions(count, gridSize);
  }

  for (let i = 0; i < count; i++) {
    positions.push({ x: startX, y: startY });
    if (startX < gridSize - 1) {
      startX++;
    } else {
      startX = 0;
      startY = (startY + 1) % gridSize;
    }
  }

  return positions;
}

/**
 * Generates an array of periodic table elements with their details
 * @param count - Number of elements to generate
 * @returns Array of strings containing element symbol, name, and atomic number
 */
export const generatePeriodicTableElements = (count: number): string[] => {
  const elements = Object.values(periodicTable);
  const shuffled = elements.sort(() => 0.5 - Math.random());
  return shuffled
    .slice(0, count)
    .map((element) => `${element.symbol} - ${element.name} - ${element.atomicNumber}`);
};

/**
 * Generates an array of random science terms
 * Repeats terms if count exceeds available unique terms
 * @param count - Number of terms to generate
 * @returns Array of science terms
 */
export const generateScienceTerms = (count: number): string[] => {
  const allTerms = Object.values(scienceTermsData)
    .flat()
    .filter((term): term is string => typeof term === "string");

  if (count > allTerms.length) {
    const repeatedTerms = [];
    while (repeatedTerms.length < count) {
      repeatedTerms.push(...shuffleArray([...allTerms]));
    }
    return repeatedTerms.slice(0, count);
  }

  return shuffleArray(allTerms).slice(0, count);
};

/**
 * Generates an array of random nursing terms
 * Repeats terms if count exceeds available unique terms
 * @param count - Number of terms to generate
 * @returns Array of nursing terms
 */
export const generateNursingTerms = (count: number): string[] => {
  const allTerms = Object.values(nursingTermsData)
    .flat()
    .filter((term): term is string => typeof term === "string");

  if (count > allTerms.length) {
    const repeatedTerms = [];
    while (repeatedTerms.length < count) {
      repeatedTerms.push(...shuffleArray([...allTerms]));
    }
    return repeatedTerms.slice(0, count);
  }

  return shuffleArray(allTerms).slice(0, count);
};

/**
 * Generates a complete list of all periodic table elements
 * @returns Array of strings containing element details
 */
export const generateFullPeriodicTable = (): string[] => {
  return Object.values(periodicTable).map(
    (element) => `${element.symbol} - ${element.name} - ${element.atomicNumber}`
  );
};

/**
 * Returns all science terms in random order
 * @returns Array of all available science terms
 */
export const getAllScienceTerms = (): string[] => {
  const allTerms = Object.values(scienceTermsData)
    .flat()
    .filter((term): term is string => typeof term === "string");
  return shuffleArray(allTerms);
};

/**
 * Returns all nursing terms in random order
 * @returns Array of all available nursing terms
 */
export const getAllNursingTerms = (): string[] => {
  const allTerms = Object.values(nursingTermsData)
    .flat()
    .filter((term): term is string => typeof term === "string");
  return shuffleArray(allTerms);
};

// Initialize the alertShown property if it doesn't exist
if (typeof window.alertShown === "undefined") {
  window.alertShown = false;
}

/**
 * Helper function to shuffle an array using Fisher-Yates algorithm
 * @param array - Array to shuffle
 * @returns Shuffled array
 */
function shuffleArray<T>(array: T[]): T[] {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}
