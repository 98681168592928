import { useTheme } from "../context/ThemeContext";
import { collectionColorSchemes } from "../constants/colorSchemes";

/**
 * Interface for BubbleText component props
 */
interface BubbleTextProps {
  /** The content to be rendered with the bubble text effect */
  children: React.ReactNode;
  /** Optional CSS class names to be applied to the container */
  className?: string;
}

/**
 * BubbleText component that renders text with a colorful bubble effect.
 * Each character is styled with a unique background color from a predefined scheme,
 * with support for colorblind mode.
 *
 * @param props - The component props
 * @returns A styled text component where each character has a bubble effect
 */
const BubbleText: React.FC<BubbleTextProps> = ({
  children,
  className = "",
}) => {
  const { theme } = useTheme();
  const text = children?.toString() || "";
  let colorIndex = 0;

  // Get adjusted color schemes based on colorblind settings
  const adjustedColorSchemes = collectionColorSchemes.map((scheme) => ({
    ...scheme,
    backgroundColor: theme.isColorblindMode
      ? theme.adjustColorForColorblindness(scheme.backgroundColor)
      : scheme.backgroundColor,
  }));

  return (
    <span className={className}>
      {text.split("").map((char, index) => {
        if (char === " ") {
          return <span key={index}>&nbsp;</span>;
        }

        const currentColorIndex = colorIndex++;
        const background =
          adjustedColorSchemes[currentColorIndex % adjustedColorSchemes.length]
            .backgroundColor;

        return (
          <span
            key={index}
            style={{
              position: "relative",
              color: background,
              WebkitTextStroke: "2px black",
              fontFamily:
                "'HappyParagraphs-Regular', 'Chalkboard SE', sans-serif",
              fontWeight: "bold",
              display: "inline-block",
              transition: "color 0.3s ease",
            }}
          >
            {char}
          </span>
        );
      })}
    </span>
  );
};

export default BubbleText;
