import React from "react";

/**
 * Props for the ErrorBoundary component
 */
interface ErrorBoundaryProps {
  /** Child components to be rendered within the error boundary */
  children: React.ReactNode;
}

/**
 * State interface for the ErrorBoundary component
 */
interface ErrorBoundaryState {
  /** Indicates whether an error has occurred */
  hasError: boolean;
}

/**
 * Error Boundary component that catches JavaScript errors in child component tree.
 * Prevents the entire app from crashing and displays a fallback UI when errors occur.
 * 
 * @extends React.Component<ErrorBoundaryProps, ErrorBoundaryState>
 */
class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): ErrorBoundaryState {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    // You can also log the error to an error reporting service
    console.error("Error boundary caught an error", error, info);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <h1 className="p-4 text-2xl font-bold text-red-600">
          Something went wrong.
        </h1>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
