interface ImageVerificationResult {
  isValid: boolean;
  message: string;
}

export const verifyImage = async (file: File): Promise<ImageVerificationResult> => {
  // Check file size (e.g., max 5MB)
  const maxSize = 5 * 1024 * 1024; // 5MB in bytes
  if (file.size > maxSize) {
    return {
      isValid: false,
      message: "Image size must be less than 5MB"
    };
  }

  // Check file type
  const allowedTypes = ["image/jpeg", "image/png", "image/gif", "image/webp"];
  if (!allowedTypes.includes(file.type)) {
    return {
      isValid: false,
      message: "Only JPEG, PNG, GIF, and WebP images are allowed"
    };
  }

  // Check image dimensions
  try {
    const dimensions = await getImageDimensions(file);
    const maxDimension = 4096; // Maximum width or height
    
    if (dimensions.width > maxDimension || dimensions.height > maxDimension) {
      return {
        isValid: false,
        message: `Image dimensions must be ${maxDimension}x${maxDimension} pixels or smaller`
      };
    }
  } catch (error) {
    console.error("Error verifying image dimensions:", error);
    return {
      isValid: false,
      message: "Failed to verify image dimensions"
    };
  }

  return {
    isValid: true,
    message: "Image verified successfully"
  };
};

const getImageDimensions = (file: File): Promise<{ width: number; height: number }> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = URL.createObjectURL(file);
    
    img.onload = () => {
      URL.revokeObjectURL(img.src);
      resolve({
        width: img.width,
        height: img.height
      });
    };
    
    img.onerror = () => {
      URL.revokeObjectURL(img.src);
      reject(new Error("Failed to load image"));
    };
  });
};
