import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";
import { Step, CallBackProps } from "react-joyride";

/**
 * Extended interface for tour callback properties
 */
export interface ExtendedCallBackProps extends CallBackProps {
  /** Name of the current tour */
  tourName?: string;
}

/**
 * Interface defining tour context functionality
 */
interface TourContextType {
  /** Record of completed tours */
  toursCompleted: Record<string, boolean>;
  /** Function to start a new tour */
  startTour: (steps: Step[]) => void;
  /** Function to mark a tour as complete */
  completeTour: (tourName: string) => void;
  /** Function to reset tour state */
  resetTourState: () => void;
  /** Flag indicating if a tour is currently running */
  isTourRunning: boolean;
  /** Function to set tour running state */
  setIsTourRunning: (isRunning: boolean) => void;
  /** Flag indicating if guided tours are enabled */
  isGuidedTourEnabled: boolean;
  /** Function to toggle guided tour functionality */
  setIsGuidedTourEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  /** Current step index in the tour */
  currentTourStep: number;
  /** Function to set current tour step */
  setCurrentTourStep: (step: number) => void;
}

// Create the context with a default value of null
const TourContext = createContext<TourContextType | null>(null);

/**
 * Provider component that manages guided tour functionality.
 * Handles tour state, progress tracking, and user preferences.
 * Persists tour completion status in localStorage.
 *
 * @param props - The provider props
 * @returns A context provider for guided tours
 */
export const TourProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [toursCompleted, setToursCompleted] = useState<Record<string, boolean>>(
    {},
  );
  const [isTourRunning, setIsTourRunning] = useState<boolean>(false);
  const [isGuidedTourEnabled, setIsGuidedTourEnabled] = useState<boolean>(
    () => {
      const storedPreference = localStorage.getItem("guidedTourEnabled");
      return storedPreference ? JSON.parse(storedPreference) : true;
    },
  );
  const [currentTourStep, setCurrentTourStep] = useState<number>(0);

  useEffect(() => {
    const storedTours = localStorage.getItem("toursCompleted");
    if (storedTours) {
      setToursCompleted(JSON.parse(storedTours));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(
      "guidedTourEnabled",
      JSON.stringify(isGuidedTourEnabled),
    );
  }, [isGuidedTourEnabled]);

  const resetTourState = () => {
    setIsTourRunning(false);
    setCurrentTourStep(0);
    // Clear any stored tour state in localStorage
    localStorage.removeItem("currentTourStep");
  };

  const startTour = (tourSteps: Step[]) => {
    resetTourState(); // Reset state before starting new tour
    setIsTourRunning(true);
    setCurrentTourStep(0);
    console.log("Starting tour with steps:", tourSteps);
  };

  const completeTour = (tourName: string) => {
    if (tourName !== "navbar") {
      const updatedTours = { ...toursCompleted, [tourName]: true };
      setToursCompleted(updatedTours);
      localStorage.setItem("toursCompleted", JSON.stringify(updatedTours));
    }
    resetTourState();
  };

  return (
    <TourContext.Provider
      value={{
        toursCompleted,
        startTour,
        completeTour,
        resetTourState,
        isTourRunning,
        setIsTourRunning,
        isGuidedTourEnabled,
        setIsGuidedTourEnabled,
        currentTourStep,
        setCurrentTourStep,
      }}
    >
      {children}
    </TourContext.Provider>
  );
};

export const useTour = () => {
  const context = useContext(TourContext);
  if (!context) {
    throw new Error("useTour must be used within a TourProvider");
  }
  return context;
};
