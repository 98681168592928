import React, { useState, useEffect } from "react";
import GuidedTour from "../../components/GuidedTour";
import { tourStepsMultipleWords } from "./tourStepsMultipleWords";
import { useTour } from "../../context/TourContext";
import { useNavigate } from "react-router-dom";
import BubbleText from "../../components/BubbleText";
import { useTheme } from "../../context/ThemeContext";
import { saveGameCollection, getGameCollections, Collection, updateGameCollection, deleteCollection } from "../../api";
import { useAuth0 } from "@auth0/auth0-react";

/**
 * @fileoverview A word-matching game component where players connect multiple related words.
 * 
 * @component MultipleWordsGame
 * @description An interactive game where players match related words together.
 * Supports custom word sets, timed challenges, and collection management.
 * 
 * @typedef {Object} GameItem
 * @property {string} word - The word to match
 * @property {string} imageUrl - In this context, used as a matching identifier
 * 
 * Features:
 * - Custom word set creation
 * - Drag-and-drop word matching
 * - Timed and untimed modes
 * - Progress tracking
 * - Collection management (save, load, update, delete)
 * - Visual connection lines between matched words
 * - Guided tour integration
 * 
 * Game Mechanics:
 * - Drag words to their matches
 * - Visual feedback for correct/incorrect matches
 * - Score tracking and completion time recording
 */

interface GameItem {
  word: string;
  imageUrl: string;
}

const MultipleWordsGame: React.FC = () => {
  const { isGuidedTourEnabled } = useTour();
  const [isGameStarted, setIsGameStarted] = useState<boolean>(false);
  const [connections, setConnections] = useState<{ [key: string]: string }>({});
  const [isTourRunning, setIsTourRunning] = useState<boolean>(false);
  const [currentTourStep, setCurrentTourStep] = useState<number>(0);
  const navigate = useNavigate();
  const { theme } = useTheme();
  const [score, setScore] = useState<number>(0);
  const [isGameCompleted, setIsGameCompleted] = useState<boolean>(false);
  const [timeLeft, setTimeLeft] = useState<number>(60);
  const [timerInput, setTimerInput] = useState<number>(60);
  const [isTimedMode, setIsTimedMode] = useState<boolean>(true);
  const [customWords, setCustomWords] = useState<string[]>([]);
  const [newWord, setNewWord] = useState<string>("");
  const [finalCompletionTime, setFinalCompletionTime] = useState<number | null>(
    null,
  );
  const [shuffledWords, setShuffledWords] = useState<string[]>([]);
  const { getAccessTokenSilently } = useAuth0();
  const [savedCollections, setSavedCollections] = useState<Collection[]>([]);
  const [collectionName, setCollectionName] = useState<string>("");

  const correctConnections: { [key: string]: string } = {};
  customWords.forEach((word) => {
    correctConnections[word] = word;
  });

  const handleAddWord = () => {
    if (newWord.trim() && !customWords.includes(newWord.trim())) {
      setCustomWords((prev) => [...prev, newWord.trim()]);
      setNewWord("");
    }
  };

  const handleRemoveWord = (wordToRemove: string) => {
    setCustomWords((prev) => prev.filter((word) => word !== wordToRemove));
    setConnections((prev) => {
      const newConnections = { ...prev };
      delete newConnections[wordToRemove];
      return newConnections;
    });
  };

  const checkConnections = () => {
    let newScore = 0;
    if (Object.keys(connections).length > 0) {
      for (const word in connections) {
        if (
          connections[word] ===
          correctConnections[word as keyof typeof correctConnections]
        ) {
          newScore++;
        }
      }
      setScore(newScore);
      if (newScore === Object.keys(correctConnections).length) {
        const finalTime = handleMatchCompletion();
        if (finalTime !== null) {
          setFinalCompletionTime(finalTime);
        }
      }
    }
  };

  const resetGame = () => {
    setConnections({});
    setScore(0);
    setIsGameCompleted(false);
    setTimeLeft(timerInput);
    setFinalCompletionTime(null);
  };

  const getTextColorClass = () => {
    return theme.backgroundColor.toLowerCase() === "#000000"
      ? "text-white"
      : "text-black";
  };

  useEffect(() => {
    const tourCompleted = localStorage.getItem("tourCompleted_multipleWords");
    if (!tourCompleted) {
      setIsTourRunning(true);
    }
  }, []);

  const handleTourComplete = () => {
    setIsTourRunning(false);
    localStorage.setItem("tourCompleted_multipleWords", "true");
  };

  const shuffleArray = (array: string[]) => {
    const newArray = [...array];
    for (let i = newArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
    }
    return newArray;
  };

  const startGame = () => {
    resetGame();
    if (isTimedMode) {
      setTimeLeft(timerInput);
    } else {
      setTimeLeft(Infinity);
    }
    setShuffledWords(shuffleArray(customWords));
    setIsGameStarted(true);
  };

  const handleConnect = (word: string, image: string) => {
    if (correctConnections[word] === image) {
      setConnections((prev) => ({ ...prev, [word]: image }));
      checkConnections();
    } else {
      const errorMessage = document.createElement('div');
      errorMessage.className = "absolute bg-red-500 text-white px-4 py-2 rounded shadow-lg";
      errorMessage.style.zIndex = "50";
      errorMessage.textContent = "Incorrect match!";
      
      const targetElement = document.getElementById(`target-${image}`);
      if (targetElement) {
        const rect = targetElement.getBoundingClientRect();
        errorMessage.style.left = `${rect.left}px`;
        errorMessage.style.top = `${rect.top - 40}px`;
        
        document.body.appendChild(errorMessage);
        
        setTimeout(() => {
          document.body.removeChild(errorMessage);
        }, 2000);
      }
    }
  };

  const handleBack = () => {
    navigate("/games");
  };

  const handleDragStart = (
    event: React.DragEvent<HTMLDivElement>,
    word: string,
  ) => {
    // Store the word being dragged
    event.dataTransfer.setData("text/plain", word);
  };

  const handleDrop = (
    event: React.DragEvent<HTMLDivElement>,
    image: string,
  ) => {
    event.preventDefault();
    const word = event.dataTransfer.getData("text/plain");
    handleConnect(word, image);
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    // Prevent default to allow drop
    event.preventDefault();
  };

  useEffect(() => {
    if (isGameStarted && Object.keys(connections).length > 0) {
      checkConnections();
    }
  }, [connections, isGameStarted]);

  const handleMatchCompletion = () => {
    if (!isGameCompleted) {
      setIsGameCompleted(true);
      if (isTimedMode) {
        const finalTime = timerInput - timeLeft;
        return finalTime;
      }
    }
    return null;
  };

  useEffect(() => {
    if (isGameStarted && timeLeft > 0 && !isGameCompleted) {
      const timer = setInterval(() => {
        setTimeLeft((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(timer);
    } else if (timeLeft === 0) {
      setIsGameCompleted(true);
    }
  }, [isGameStarted, timeLeft, isGameCompleted]);

  const goBackToSetup = () => {
    resetGame(); // Reset the game state
    setIsGameStarted(false); // Set game started to false to show setup screen
  };

  // Modify the input section to handle Enter key
  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleAddWord();
    }
  };

  const calculateLineCoordinates = (
    sourceElement: HTMLElement | null,
    targetElement: HTMLElement | null,
  ) => {
    if (!sourceElement || !targetElement) return null;

    const sourceRect = sourceElement.getBoundingClientRect();
    const targetRect = targetElement.getBoundingClientRect();
    const gameBoard = document
      .querySelector(".game-board")
      ?.getBoundingClientRect();

    if (!gameBoard) return null;

    // Calculate positions relative to game board
    const x1 = sourceRect.right - gameBoard.left;
    const y1 = sourceRect.top - gameBoard.top + sourceRect.height / 2;
    const x2 = targetRect.left - gameBoard.left;
    const y2 = targetRect.top - gameBoard.top + targetRect.height / 2;

    return { x1, y1, x2, y2 };
  };

  const handleSaveCollection = async () => {
    if (customWords.length === 0) {
      alert("Please add some words before saving!");
      return;
    }
    
    if (!collectionName.trim()) {
      alert("Please enter a name for your collection!");
      return;
    }
    
    try {
      // Convert words to the expected GamePair format
      const gamePairs = customWords.map(word => ({
        word,
        imageUrl: word // In this case, we're using the word as the imageUrl
      }));

      await saveGameCollection(
        collectionName,
        gamePairs,
        "multiple_words",
        getAccessTokenSilently
      );
      alert("Collection saved successfully!");
      // Refresh the saved collections list
      const collections = await loadSavedCollections();
      if (collections) setSavedCollections(collections);
    } catch (error) {
      console.error("Failed to save collection:", error);
      alert("Failed to save collection. Please try again.");
    }
  };

  const loadSavedCollections = async () => {
    try {
      const collections = await getGameCollections(
        "multiple_words",
        getAccessTokenSilently
      );
      // Add UI to display and select from saved collections
      return collections;
    } catch (error) {
      console.error("Failed to load collections:", error);
    }
  };

  useEffect(() => {
    loadSavedCollections().then(collections => {
      if (collections) setSavedCollections(collections);
    });
  }, []);

  const handleUpdateCollection = async (selected: Collection) => {
    if (window.confirm("Are you sure you want to update this collection?")) {
      try {
        const gamePairs = customWords.map(word => ({
          word,
          imageUrl: word
        }));

        await updateGameCollection(
          selected.collection_id,
          collectionName,
          gamePairs,
          getAccessTokenSilently
        );
        
        // Update the local state
        const updatedCollection = {
          ...selected,
          name: collectionName,
          description: JSON.stringify(gamePairs)
        };
        setSavedCollections(prev => 
          prev.map(c => c.collection_id === selected.collection_id ? updatedCollection : c)
        );
        alert("Collection updated successfully!");
      } catch (error) {
        console.error("Failed to update collection:", error);
        alert("Failed to update collection. Please try again.");
      }
    }
  };

  return (
    <div className={`page-container ${getTextColorClass()}`}>
      <button
        type="button"
        onClick={handleBack}
        className="absolute left-4 z-40 mt-[20px] rounded border border-black bg-red-500 px-4 py-2 text-white hover:bg-red-600"
      >
        Back to Games
      </button>
      {isGameStarted && (
        <div className="absolute right-4 z-40 mt-[20px] flex space-x-4">
          <button
            type="button"
            onClick={resetGame}
            className="reset-button mt-5 max-w-[300px] cursor-pointer rounded border border-black bg-yellow-500 p-2.5 text-base font-bold uppercase text-black transition-all duration-300 hover:scale-105 hover:bg-yellow-600 active:scale-95 active:bg-yellow-700"
          >
            Reset Game
          </button>
          <button
            type="button"
            onClick={goBackToSetup}
            className="back-to-setup-button mt-5 max-w-[300px] cursor-pointer rounded border border-black bg-blue-500 p-2.5 text-base font-bold uppercase text-white transition-all duration-300 hover:bg-blue-600"
          >
            Back to Setup
          </button>
        </div>
      )}
      <h1 className="mb-4 text-3xl font-bold">
        <BubbleText>Multiple Words Game</BubbleText>
      </h1>
      {!isGameStarted ? (
        <div className="game-instructions flex flex-col items-center">
          <p>Add your own words to match!</p>

          <div className="mt-4 flex gap-2">
            <input
              type="text"
              value={newWord}
              onChange={(e) => setNewWord(e.target.value)}
              onKeyPress={handleKeyPress}
              className="rounded border border-gray-300 p-2"
              placeholder="Enter a word"
            />
            <button
              onClick={handleAddWord}
              className="rounded bg-green-500 px-4 py-2 text-white hover:bg-green-600"
            >
              Add Word
            </button>
          </div>

          <div className="mt-4 flex max-w-[600px] flex-wrap gap-2">
            {customWords.map((word) => (
              <div
                key={word}
                className="flex items-center rounded bg-gray-100 px-3 py-1"
              >
                <span>{word}</span>
                <button
                  onClick={() => handleRemoveWord(word)}
                  className="ml-2 text-red-500 hover:text-red-700"
                >
                  ×
                </button>
              </div>
            ))}
          </div>

          <p className="inherit mt-4">
            Enter the timer length in seconds for the game:
          </p>
          <input
            type="number"
            value={timerInput}
            onChange={(e) => setTimerInput(Number(e.target.value))}
            className="mt-2 rounded border border-gray-300 p-2"
            placeholder="Enter timer length in seconds"
          />
          <label className="mt-2 flex items-center">
            <input
              type="checkbox"
              checked={!isTimedMode}
              onChange={() => setIsTimedMode((prev) => !prev)}
              className="mr-2"
            />
            No Time Limit
          </label>
          <button
            type="button"
            onClick={startGame}
            disabled={customWords.length === 0}
            className={`start-button bg-light-blue hover:bg-hover-blue active:bg-active-blue mt-5 max-w-[300px] cursor-pointer rounded border border-black p-2.5 text-base font-bold uppercase text-black transition-all duration-300 hover:scale-105 active:scale-95 ${
              customWords.length === 0 ? "cursor-not-allowed opacity-50" : ""
            }`}
          >
            Start Game
          </button>
          <div className="mt-4">
            <input
              type="text"
              value={collectionName}
              onChange={(e) => setCollectionName(e.target.value)}
              placeholder="Enter collection name"
              className="mr-2 rounded border p-2"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSaveCollection();
                }
              }}
            />
            <button
              type="button"
              onClick={handleSaveCollection}
              className="mt-4 rounded bg-blue-500 px-4 py-2 text-white hover:bg-blue-600"
            >
              Save Collection
            </button>

            {savedCollections.length > 0 && (
              <div className="mt-2 flex gap-2">
                <button
                  type="button"
                  onClick={async () => {
                    const selected = savedCollections.find(c => c.name === collectionName);
                    if (selected) {
                      handleUpdateCollection(selected);
                    }
                  }}
                  className="rounded bg-blue-500 px-4 py-2 text-white hover:bg-blue-600"
                >
                  Update Selected
                </button>
                <button
                  type="button"
                  onClick={async () => {
                    const selected = savedCollections.find(c => c.name === collectionName);
                    if (selected && window.confirm("Are you sure you want to delete this collection?")) {
                      await deleteCollection(selected.collection_id, getAccessTokenSilently);
                      const collections = await loadSavedCollections();
                      if (collections) setSavedCollections(collections);
                      setCollectionName("");
                      alert("Collection deleted successfully!");
                    }
                  }}
                  className="rounded bg-red-500 px-4 py-2 text-white hover:bg-red-600"
                >
                  Delete Selected
                </button>
              </div>
            )}
          </div>
          <div className="mt-4">
            <select
              title="Select a saved collection"
              onChange={(e) => {
                const collection = savedCollections.find(c => c.collection_id === Number(e.target.value));
                if (collection) {
                  try {
                    // Debug log
                    console.log("Selected collection:", collection);
                    console.log("Collection description:", collection.description);

                    // Parse the double-stringified JSON
                    let items: GameItem[];
                    if (typeof collection.description === "string") {
                      // First parse to get the string array
                      const firstParse = JSON.parse(collection.description);
                      // If it's still a string, parse again
                      items = typeof firstParse === "string" ? JSON.parse(firstParse) : firstParse;
                    } else {
                      items = collection.description as GameItem[];
                    }
                    
                    console.log("Parsed items:", items);

                    // Extract words from the items array
                    const words = items
                      .map((item: GameItem) => item.word)
                      .filter((word): word is string => typeof word === "string" && word.length > 0);

                    console.log("Extracted words:", words);
                    
                    if (words.length > 0) {
                      setCustomWords(words);
                      setCollectionName(collection.name);
                    } else {
                      throw new Error("No valid words found in collection");
                    }
                  } catch (error) {
                    console.error("Error parsing collection data:", error);
                    console.error("Collection data:", collection);
                    alert("Error loading collection: " + (error instanceof Error ? error.message : "Unknown error"));
                  }
                }
              }}
              className="mr-2 rounded border p-2"
            >
              <option value="">Select a saved collection</option>
              {savedCollections.map(collection => (
                <option key={collection.collection_id} value={collection.collection_id}>
                  {collection.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      ) : (
        <div className="game-board relative">
          <div className="flex flex-col items-center">
            <p>Time Left: {timeLeft} seconds</p>
            <p>Score: {score}</p>
            <div className="flex justify-center gap-20">
              <div className="words-section">
                {customWords.map((word) => (
                  <div
                    key={word}
                    draggable
                    onDragStart={(event) => handleDragStart(event, word)}
                    className="m-2 cursor-pointer"
                    id={`source-${word}`}
                  >
                    {word}
                  </div>
                ))}
              </div>
              <div className="cards-section">
                {shuffledWords.map((word) => (
                  <div
                    key={word}
                    className="m-2"
                    onDrop={(event) => handleDrop(event, word)}
                    onDragOver={handleDragOver}
                    id={`target-${word}`}
                  >
                    {word}
                  </div>
                ))}
              </div>
            </div>

            <svg
              className="pointer-events-none absolute left-0 top-0 size-full"
              style={{ zIndex: 1 }}
            >
              {Object.entries(connections).map(([sourceWord, targetWord]) => {
                if (correctConnections[sourceWord] !== targetWord) return null;
                
                const sourceElement = document.getElementById(`source-${sourceWord}`);
                const targetElement = document.getElementById(`target-${targetWord}`);
                const coords = calculateLineCoordinates(sourceElement, targetElement);

                if (!coords) return null;

                return (
                  <g key={sourceWord}>
                    <line
                      x1={coords.x1}
                      y1={coords.y1}
                      x2={coords.x2}
                      y2={coords.y2}
                      stroke="#000"
                      strokeWidth="2"
                      markerEnd="url(#arrowhead)"
                    />
                  </g>
                );
              })}
              <defs>
                <marker
                  id="arrowhead"
                  markerWidth="10"
                  markerHeight="7"
                  refX="9"
                  refY="3.5"
                  orient="auto"
                >
                  <polygon points="0 0, 10 3.5, 0 7" fill="#000" />
                </marker>
              </defs>
            </svg>
          </div>
        </div>
      )}

      <GuidedTour
        steps={tourStepsMultipleWords()}
        isRunning={isTourRunning && isGuidedTourEnabled}
        onComplete={handleTourComplete}
        currentStep={currentTourStep}
        onStepChange={setCurrentTourStep}
        tourName="multipleWords"
      />

      {isGameStarted && isGameCompleted && (
        <div className="flex flex-col items-center">
          <p>Congratulations! You've completed the game!</p>
          {isTimedMode && finalCompletionTime !== null && (
            <p>Your completion time: {finalCompletionTime} seconds</p>
          )}
          <button
            type="button"
            onClick={resetGame}
            className="reset-button mt-5 rounded border border-black bg-blue-500 px-4 py-2 text-white hover:bg-blue-600"
          >
            Play Again
          </button>
        </div>
      )}
    </div>
  );
};

export default MultipleWordsGame;
