import { VisibilityStates } from "../types/VisibilityStates";

/**
 * Creates a default visibility state object for UI elements.
 * Used to initialize component visibility in guided tours.
 * All visibility flags are initially set to false.
 * 
 * @returns A VisibilityStates object with default values
 */
export const getDefaultVisibilityStates = (): VisibilityStates => ({
  /** Controls visibility of dot count type selector */
  isDotCountTypeVisible: false,
  /** Controls visibility of minimum dots input */
  isMinDotsVisible: false,
  /** Controls visibility of maximum dots input */
  isMaxDotsVisible: false,
  isTypeSelectVisible: false,
  isItemCountVisible: false,
  isCollectionItemCountVisible: false,
  isDotColorVisible: false,
  isDotShapeVisible: false,
  isGenerateRandomSequenceButtonVisible: false,
  isFileUploadVisible: false,
  isClearButtonVisible: false,
  isGeneratedSequencePreviewVisible: false,
  isSearchInputVisible: false,
  isSortSelectVisible: false,
  isCollectionsGridVisible: false,
  isPreviewButtonVisible: false,
  isSaveButtonVisible: false,
  isItemPreviewVisible: false,
  isMathProblemVisible: false,
  isDotButtonVisible: false,
  isImageUploadVisible: false,
  isNameInputVisible: false,
  isAddNameButtonVisible: false,
  isSpinButtonVisible: false,
  isNamesListVisible: false,
  isCollectionNameVisible: false,
  isCategorySelectVisible: false,
  isStageSelectVisible: false,
  isPublicCheckboxVisible: false,
  isSubmitButtonVisible: false,
  isCollectionCardVisible: false,
  isStartCollectionButtonVisible: false,
  isEditCollectionButtonVisible: false,
  isDeleteCollectionButtonVisible: false,
  isSessionSettingsModalVisible: false,
  isEditCollectionModalVisible: false,
  isDuplicateCollectionModalVisible: false,
  isCollectionPreviewModalVisible: false,
  isNextButtonVisible: false,
});
