import React from "react";

/**
 * Props for the WheelSegment component
 */
interface WheelSegmentProps {
  /** Name displayed in the segment */
  name: string;
  /** Index for unique identification */
  index: number;
  /** Angle for the text positioning */
  angle: number;
  /** Radius of the wheel for text positioning */
  radius: number;
  /** Callback function to remove the segment */
  onRemove: () => void;
  /** Callback function to edit the segment name */
  onEdit: (newName: string) => void;
  /** Callback function for drag start event */
  onDragStart: (e: React.DragEvent, index: number) => void;
  /** Callback function for drag over event */
  onDragOver: (e: React.DragEvent) => void;
  /** Callback function for drop event */
  onDrop: (e: React.DragEvent, index: number) => void;
}

/**
 * Component that represents a draggable segment in a wheel interface.
 * Supports editing, removal, and drag-and-drop reordering.
 * Provides visual feedback for user interactions.
 *
 * @param props - The component props
 * @returns A list item representing a wheel segment
 */
const WheelSegment: React.FC<WheelSegmentProps> = ({
  name,
  index,
  onRemove,
  onEdit,
  onDragStart,
  onDragOver,
  onDrop,
}) => {
  return (
    <li
      className="mb-2 flex items-center"
      draggable
      onDragStart={(e) => onDragStart(e, index)}
      onDragOver={(e) => onDragOver(e)}
      onDrop={(e) => onDrop(e, index)}
    >
      <input
        type="text"
        value={name}
        onChange={(e) => onEdit(e.target.value)}
        className={`font-teacher mr-2 grow rounded border border-current ${"bg-white text-black"} p-2 text-base`}
        placeholder="Edit name"
        title={`Edit name ${index + 1}`}
      />
      <button
        type="button"
        onClick={onRemove}
        className="rounded border border-black bg-red-500 px-2 py-1 text-white transition duration-300 hover:bg-red-600"
      >
        Remove
      </button>
    </li>
  );
};

export default WheelSegment;
