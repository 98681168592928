import React, { useState, useEffect, useRef } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { getCurrentUser, submitFeedback } from "../api";
import { verifyImage } from "../utils/imageVerification";

/**
 * Interface for file preview information
 */
interface FilePreview {
  /** URL of the preview image/document */
  url: string;
  /** MIME type of the file */
  type: string;
}

/**
 * Props for the FeedbackForm component
 */
interface FeedbackFormProps {
  /** Callback function to close the feedback form */
  onClose: () => void;
}

/**
 * Modal component that allows users to submit feedback with optional file attachments.
 * Supports text feedback and image/PDF uploads with preview functionality.
 * Handles authentication state and user information for submission.
 *
 * @param props - The component props
 * @returns A modal form for submitting feedback
 */
const FeedbackForm: React.FC<FeedbackFormProps> = ({ onClose }) => {
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");
  const [images, setImages] = useState<File[]>([]);
  const [imagePreviewUrls, setImagePreviewUrls] = useState<FilePreview[]>([]);
  const { getAccessTokenSilently, isAuthenticated, user } = useAuth0();

  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const modalRef = useRef<HTMLDivElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    textareaRef.current?.focus();

    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  const handleImageUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const files = Array.from(e.target.files);
      const validFiles: File[] = [];
      const validPreviews: FilePreview[] = [];
      const errors: string[] = [];

      for (const file of files) {
        const result = await verifyImage(file);
        if (result.isValid) {
          validFiles.push(file);
          validPreviews.push({
            url: URL.createObjectURL(file),
            type: file.type
          });
        } else {
          errors.push(`${file.name}: ${result.message}`);
        }
      }

      if (validFiles.length > 0) {
        setImages(prev => [...prev, ...validFiles]);
        setImagePreviewUrls(prev => [...prev, ...validPreviews]);
      }

      if (errors.length > 0) {
        setError(`Some files were not added:\n${errors.join("\n")}`);
      }
    }
  };

  const handleRemoveImage = (index: number) => {
    URL.revokeObjectURL(imagePreviewUrls[index].url);
    setImages((prev) => prev.filter((_, i) => i !== index));
    setImagePreviewUrls((prev) => prev.filter((_, i) => i !== index));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError("");
    console.log("Starting feedback submission process...");
    console.log("Current images:", images);

    try {
      let displayName = "Anonymous User";

      if (isAuthenticated) {
        try {
          console.log("Getting user profile...");
          const userProfile = await getCurrentUser(getAccessTokenSilently);
          displayName =
            userProfile?.display_name || user?.name || "Anonymous User";
          console.log("Using display name:", displayName);
        } catch (error) {
          console.warn(
            "Could not get user profile, using Anonymous User",
            error,
          );
        }
      }

      console.log("Submitting feedback with images:", images.length);
      await submitFeedback(
        message,
        displayName,
        getAccessTokenSilently,
        images,
      );

      console.log("Feedback submitted successfully");
      setSuccess(true);
      setMessage("");
      setImages([]);
      setImagePreviewUrls([]);
      setTimeout(() => {
        onClose();
      }, 2000);
    } catch (error) {
      console.error("Detailed submission error:", error);
      setError("Failed to submit feedback. Please try again.");
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e as unknown as React.FormEvent);
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4">
      <div
        ref={modalRef}
        className="w-full max-w-md rounded-lg border border-black bg-white p-6"
      >
        <h2 className="mb-4 text-xl font-bold text-gray-800">Feedback</h2>
        {success ? (
          <p className="text-green-600">Thank you for your feedback!</p>
        ) : (
          <form onSubmit={handleSubmit} className="space-y-4">
            <textarea
              ref={textareaRef}
              className="w-full rounded border border-black p-2 text-gray-800"
              placeholder="Your feedback"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              rows={4}
              required
              onKeyDown={handleKeyDown}
            />

            <div className="space-y-2">
              <label
                htmlFor="feedback-image-upload"
                className="mb-1 block text-sm font-medium text-gray-700"
              >
                Upload Images or PDFs (Optional)
              </label>
              <input
                ref={fileInputRef}
                id="feedback-image-upload"
                type="file"
                accept="image/*,.pdf"
                multiple
                onChange={handleImageUpload}
                className="hidden"
                aria-label="Upload feedback images and documents"
              />
              <button
                type="button"
                onClick={() => fileInputRef.current?.click()}
                className="rounded border border-gray-300 bg-white px-4 py-2 text-sm text-gray-700 hover:bg-gray-50"
                aria-controls="feedback-image-upload"
              >
                Add Images
              </button>

              {imagePreviewUrls.length > 0 && (
                <div
                  className="grid grid-cols-2 gap-2"
                  role="list"
                  aria-label="Uploaded images preview"
                >
                  {imagePreviewUrls.map((file, index) => (
                    <div key={index} className="relative" role="listitem">
                      {file.type.startsWith("image/") ? (
                        <img
                          src={file.url}
                          alt={`Preview of uploaded image ${index + 1}`}
                          className="size-24 rounded object-cover"
                        />
                      ) : (
                        <div className="flex size-24 items-center justify-center rounded border border-gray-300 bg-gray-50">
                          <span className="text-sm">PDF Document</span>
                        </div>
                      )}
                      <button
                        type="button"
                        onClick={() => handleRemoveImage(index)}
                        className="absolute -right-2 -top-2 rounded-full bg-red-500 p-1 text-white hover:bg-red-600"
                        aria-label={`Remove file ${index + 1}`}
                      >
                        ×
                      </button>
                    </div>
                  ))}
                </div>
              )}
            </div>

            {error && <p className="text-red-500">{error}</p>}
            <div className="flex justify-end space-x-2">
              <button
                type="button"
                onClick={onClose}
                className="rounded border border-black bg-gray-200 px-4 py-2 text-gray-800"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="rounded border border-black bg-blue-500 px-4 py-2 text-white"
              >
                Submit
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default FeedbackForm;
