import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
  Dispatch,
  SetStateAction,
  useRef,
} from "react";
import { adjustColorForColorblindness as adjustColor } from "../utils/colorAdjustment";
import { colorSchemes } from "../constants/colorSchemes";

/**
 * Interface defining theme properties and appearance settings
 */
interface Theme {
  /** Display name of the theme */
  name: string;
  /** Background color in hex format */
  backgroundColor: string;
  /** Text color in hex format */
  textColor: string;
  /** Original text color before adjustments */
  originalTextColor: string;
  /** Original background color before adjustments */
  originalBackgroundColor: string;
  /** Optional CSS class name */
  className?: string;
  /** Display text color after adjustments */
  displayTextColor?: string;
  /** Display background color after adjustments */
  displayBackgroundColor?: string;
  /** Optional background image URL */
  backgroundImage?: string;
  /** Flag for colorblind mode */
  isColorblindMode: boolean;
  /** Type of colorblindness adjustment */
  colorblindType: string;
  /** Main font family */
  font: string;
  /** Heading font family */
  headingFont: string;
  /** Button font family */
  buttonFont: string;
  /** Function to adjust colors for colorblindness */
  adjustColorForColorblindness: (color: string) => string;
  /** Display font family */
  displayFont: string;
}

/**
 * Interface defining theme context functionality
 */
interface ThemeContextType {
  /** Current theme settings */
  theme: Theme;
  /** Function to set the entire theme */
  setTheme: Dispatch<SetStateAction<Theme>>;
  /** Function to update specific theme properties */
  updateTheme: (newTheme: Theme) => void;
  /** Function to set display text color */
  setDisplayTextColor: (color: string) => void;
  /** Function to set display background color */
  setDisplayBackgroundColor: (color: string) => void;
  /** Function to toggle colorblind mode */
  setColorblindMode: (isEnabled: boolean) => void;
  /** Function to set colorblind type */
  setColorblindType: (type: string) => void;
  /** Function to adjust colors for colorblindness */
  adjustColorForColorblindness: (color: string) => string;
  /** Function to set main font */
  setFont: (font: string) => void;
  /** Function to set heading font */
  setHeadingFont: (font: string) => void;
  /** Function to set button font */
  setButtonFont: (font: string) => void;
  /** Function to set main text color */
  setMainTextColor: (color: string) => void;
  /** Function to set display font */
  setDisplayFont: (font: string) => void;
}

// Move the function outside of the ThemeProvider
export const setThemeWithColorAdjustment = (newTheme: Theme) => {
  return {
    ...newTheme,
    displayTextColor: "#000000", // Set default text color
  };
};

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

/**
 * Provider component that manages theme state and appearance settings.
 * Handles color adjustments, font settings, and persistence in localStorage.
 * Supports colorblind modes and dynamic theme updates.
 *
 * @param props - The provider props
 * @returns A context provider for theme management
 */
export const ThemeProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const getInitialTheme = (): Theme => {
    try {
      const savedTheme = localStorage.getItem("app-theme");
      if (savedTheme) {
        const parsedTheme = JSON.parse(savedTheme);

        // Ensure colorblind adjustments only happen when enabled
        const displayTextColor = parsedTheme.isColorblindMode
          ? adjustColor(
              parsedTheme.originalTextColor || parsedTheme.textColor,
              parsedTheme.colorblindType,
            )
          : parsedTheme.originalTextColor || parsedTheme.textColor;

        const displayBackgroundColor = parsedTheme.isColorblindMode
          ? adjustColor(
              parsedTheme.originalBackgroundColor ||
                parsedTheme.backgroundColor,
              parsedTheme.colorblindType,
            )
          : parsedTheme.originalBackgroundColor || parsedTheme.backgroundColor;

        return {
          ...parsedTheme,
          displayTextColor,
          displayBackgroundColor,
          adjustColorForColorblindness: (color: string) =>
            parsedTheme.isColorblindMode
              ? adjustColor(color, parsedTheme.colorblindType)
              : color,
        };
      }
    } catch {
      console.error("Error loading theme");
    }

    // Default theme
    return {
      ...colorSchemes[2],
      isColorblindMode: false,
      colorblindType: "protanopia",
      font: '"font-happy-paragraphs-regular"',
      headingFont: '"font-happy-paragraphs-regular"',
      buttonFont: '"font-happy-paragraphs-regular"',
      originalTextColor: "#000000",
      originalBackgroundColor: "#FFFFFF",
      displayTextColor: "#000000",
      displayBackgroundColor: "#FFFFFF",
      adjustColorForColorblindness: (color: string) => color,
      displayFont: '"font-happy-paragraphs-regular"',
    };
  };

  const [theme, setTheme] = useState<Theme>(getInitialTheme());

  const setDisplayTextColor = (color: string) => {
    setTheme((prevTheme) => ({ ...prevTheme, displayTextColor: color }));
  };

  const setDisplayBackgroundColor = (color: string) => {
    setTheme((prevTheme) => ({ ...prevTheme, displayBackgroundColor: color }));
  };

  const updateTheme = (newTheme: Theme) => {
    setTheme(setThemeWithColorAdjustment(newTheme));
  };

  const setColorblindMode = (isEnabled: boolean) => {
    setTheme((prevTheme) => {
      const originalTextColor =
        prevTheme.originalTextColor || prevTheme.textColor;
      const originalBackgroundColor =
        prevTheme.originalBackgroundColor || prevTheme.backgroundColor;

      const newTheme = {
        ...prevTheme,
        isColorblindMode: isEnabled,
        originalTextColor,
        originalBackgroundColor,
      };

      if (isEnabled) {
        return {
          ...newTheme,
          displayTextColor: adjustColor(
            originalTextColor,
            prevTheme.colorblindType,
          ),
          displayBackgroundColor: adjustColor(
            originalBackgroundColor,
            prevTheme.colorblindType,
          ),
        };
      } else {
        // Set displayTextColor to black when colorblind mode is disabled
        return {
          ...newTheme,
          displayTextColor: "#000000", // Default to black text color
          displayBackgroundColor: originalBackgroundColor, // Keep original background color
        };
      }
    });
  };

  const setColorblindType = (type: string) => {
    setTheme((prevTheme) => {
      // Create a new adjustColorForColorblindness function with the new type
      const newAdjustColorForColorblindness = (color: string) =>
        adjustColor(color, type);

      const updatedTheme = {
        ...prevTheme,
        colorblindType: type,
        adjustColorForColorblindness: newAdjustColorForColorblindness,
      };

      if (prevTheme.isColorblindMode) {
        const originalTextColor =
          prevTheme.originalTextColor || prevTheme.textColor;
        const originalBackgroundColor =
          prevTheme.originalBackgroundColor || prevTheme.backgroundColor;

        return {
          ...updatedTheme,
          displayTextColor: newAdjustColorForColorblindness(originalTextColor),
          displayBackgroundColor: newAdjustColorForColorblindness(
            originalBackgroundColor,
          ),
        };
      }

      return updatedTheme;
    });
  };

  const setFont = (font: string) => {
    setTheme((prevTheme) => {
      const newTheme = { ...prevTheme, font };
      document.documentElement.style.setProperty("--font-family", font); // Set the CSS variable
      return newTheme;
    });
  };

  const setHeadingFont = (font: string) => {
    setTheme((prevTheme) => {
      // Handle special cases for fonts with spaces or quotes
      const cssFont = font.replace(/^["'](.+)["']$/, "$1");
      let formattedFont = cssFont;

      // Special handling for fonts with spaces or special names
      if (
        cssFont.includes(" ") ||
        cssFont === "KG Shake It Off" ||
        cssFont === "KG What The Teacher Wants" ||
        cssFont === "Baloo 2"
      ) {
        formattedFont = `"${cssFont}"`;
      }

      document.documentElement.style.setProperty(
        "--heading-font-family",
        formattedFont,
      );
      return { ...prevTheme, headingFont: font };
    });
  };

  const setButtonFont = (font: string) => {
    setTheme((prevTheme) => {
      // Handle special cases for fonts with spaces or quotes
      const cssFont = font.replace(/^["'](.+)["']$/, "$1");
      let formattedFont = cssFont;

      // Special handling for fonts with spaces or special names
      if (
        cssFont.includes(" ") ||
        cssFont === "KG Shake It Off" ||
        cssFont === "KG What The Teacher Wants" ||
        cssFont === "Baloo 2"
      ) {
        formattedFont = `"${cssFont}"`;
      }

      document.documentElement.style.setProperty(
        "--button-font-family",
        formattedFont,
      );
      return { ...prevTheme, buttonFont: font };
    });
  };

  const setMainTextColor = (color: string) => {
    setTheme((prevTheme) => ({
      ...prevTheme,
      originalTextColor: color,
      displayTextColor: color, // Update display text color
    }));
  };

  const setDisplayFont = (font: string) => {
    setTheme((prevTheme) => ({ ...prevTheme, displayFont: font }));
    document.documentElement.style.setProperty("--display-font-family", font);
  };

  // Add a new ref to track initial load
  const isInitialLoad = useRef(true);

  // Add this effect before the main theme effect
  useEffect(() => {
    // Initialize theme colors on mount
    if (theme.isColorblindMode) {
      const adjustedTextColor = adjustColor(
        theme.originalTextColor || theme.textColor,
        theme.colorblindType,
      );
      const adjustedBackgroundColor = adjustColor(
        theme.originalBackgroundColor || theme.backgroundColor,
        theme.colorblindType,
      );

      setTheme((prevTheme) => ({
        ...prevTheme,
        displayTextColor: adjustedTextColor,
        displayBackgroundColor: adjustedBackgroundColor,
      }));
    } else {
      // Ensure we reset to original colors when colorblind mode is off
      setTheme((prevTheme) => ({
        ...prevTheme,
        displayTextColor: prevTheme.originalTextColor || prevTheme.textColor,
        displayBackgroundColor:
          prevTheme.originalBackgroundColor || prevTheme.backgroundColor,
      }));
    }
  }, [theme.isColorblindMode]); // Add dependency on colorblind mode

  // Modify the useEffect that handles theme changes
  useEffect(() => {
    if (isInitialLoad.current) {
      // Even on initial load, we should set the saved theme
      const savedTheme = localStorage.getItem("app-theme");
      if (savedTheme) {
        const parsedTheme = JSON.parse(savedTheme);
        const currentBackgroundColor =
          parsedTheme.displayBackgroundColor || parsedTheme.backgroundColor;

        // Set the CSS variables immediately
        document.documentElement.style.setProperty(
          "--background-color",
          currentBackgroundColor,
        );
        document.documentElement.style.setProperty(
          "--display-background-color",
          currentBackgroundColor,
        );
      }
      isInitialLoad.current = false;
      return;
    }

    console.log("Applying theme changes");

    // Ensure we're using the correct colors based on colorblind mode
    const effectiveTheme = {
      ...theme,
      displayTextColor: theme.isColorblindMode
        ? adjustColor(
            theme.originalTextColor || theme.textColor,
            theme.colorblindType,
          )
        : theme.originalTextColor || theme.textColor,
      displayBackgroundColor: theme.isColorblindMode
        ? adjustColor(
            theme.originalBackgroundColor || theme.backgroundColor,
            theme.colorblindType,
          )
        : theme.originalBackgroundColor || theme.backgroundColor,
    };

    // Save the complete theme state
    localStorage.setItem("app-theme", JSON.stringify(effectiveTheme));

    // Use the effective background color
    const currentBackgroundColor =
      effectiveTheme.displayBackgroundColor || effectiveTheme.backgroundColor;

    if (theme.backgroundImage && theme.backgroundImage !== "none") {
      console.log("Setting background image:", theme.backgroundImage);
      const img = new Image();
      img.onload = () => console.log("Background image loaded successfully");
      img.onerror = (e) => {
        console.error("Failed to load background image", e);
        console.error("Image src:", theme.backgroundImage);
      };
      const imagePath = theme.backgroundImage.startsWith("/")
        ? theme.backgroundImage
        : `/${theme.backgroundImage}`;
      img.src = imagePath;
      document.body.style.backgroundImage = `url(${imagePath})`;
      document.body.style.backgroundSize = "cover";
      document.body.style.backgroundPosition = "center";
      document.body.style.backgroundRepeat = "no-repeat";
      document.body.style.backgroundColor = "transparent";
    } else {
      console.log("Setting background color to:", currentBackgroundColor);
      document.body.style.backgroundImage = "none";
      document.body.style.backgroundColor = currentBackgroundColor;
      document.documentElement.style.setProperty(
        "--background-color",
        currentBackgroundColor,
      );
    }

    // Update CSS custom properties with effective colors
    document.documentElement.style.setProperty(
      "--text-color",
      theme.originalTextColor,
    );
    document.documentElement.style.setProperty(
      "--display-background-color",
      currentBackgroundColor || "",
    );
    document.documentElement.style.setProperty(
      "--display-text-color",
      theme.displayTextColor || "",
    );

    if (theme.backgroundImage && theme.backgroundImage !== "none") {
      document.documentElement.style.setProperty(
        "--background-image",
        `url(${theme.backgroundImage})`,
      );
    } else {
      document.documentElement.style.setProperty("--background-image", "none");
    }

    document.documentElement.style.setProperty(
      "--font-family",
      effectiveTheme.font,
    );
    document.documentElement.style.setProperty(
      "--heading-font-family",
      theme.headingFont,
    );
    document.documentElement.style.setProperty(
      "--button-font-family",
      theme.buttonFont,
    );

    // Add this line to update the display font
    document.documentElement.style.setProperty(
      "--display-font-family",
      theme.displayFont || theme.font,
    );

    console.log("Theme applied");
  }, [theme]);

  const themeContextValue: ThemeContextType = {
    theme,
    setTheme,
    updateTheme,
    setDisplayTextColor,
    setDisplayBackgroundColor,
    setColorblindMode,
    setColorblindType,
    adjustColorForColorblindness: (color: string) =>
      theme.isColorblindMode ? adjustColor(color, theme.colorblindType) : color,
    setFont,
    setHeadingFont,
    setButtonFont,
    setMainTextColor,
    setDisplayFont,
  };

  return (
    <ThemeContext.Provider value={themeContextValue}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }
  return context;
};
