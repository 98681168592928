import React from "react";
import feedbackIcon from "../assets/feedback.png";

/**
 * Props for the FeedbackIcon component
 */
interface FeedbackIconProps {
  /** Callback function triggered when the feedback icon is clicked */
  onClick: () => void;
}

/**
 * Button component that displays a feedback icon.
 * Provides visual feedback on hover and click through scaling animations.
 *
 * @param props - The component props
 * @returns A button with a feedback icon
 */
const FeedbackIcon: React.FC<FeedbackIconProps> = ({ onClick }) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className="transition-transform hover:scale-110 active:scale-95"
      aria-label="Open Feedback Form"
    >
      <img
        src={feedbackIcon}
        alt="Feedback"
        className="size-8 cursor-pointer"
      />
    </button>
  );
};

export default FeedbackIcon;
