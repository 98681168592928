import React from "react";
import { useUserRole } from "../context/UserRoleContext";

/**
 * Props for the RoleSelection component
 */
interface RoleSelectionProps {
  /** Callback function to handle role changes, returns a Promise */
  onRoleChange: (newRole: string) => Promise<void>;
}

/**
 * Component that allows users to select their role (teacher or student).
 * Manages role state and triggers callback on role changes.
 * Provides radio button interface for role selection.
 *
 * @param props - The component props
 * @returns A form for selecting user role
 */
const RoleSelection: React.FC<RoleSelectionProps> = ({ onRoleChange }) => {
  const { userRole, setUserRole } = useUserRole();

  const handleRoleChange = async (newRole: string) => {
    setUserRole(newRole);
    await onRoleChange(newRole);
  };

  return (
    <div className="role-selection">
      <div className="mb-4 text-center">
        <h2>Select Your Role</h2>
      </div>
      <div className="space-y-2">
        <label className="flex items-center">
          <input
            type="radio"
            value="teacher"
            checked={userRole === "teacher"}
            onChange={() => handleRoleChange("teacher")}
          />
          <span className="ml-2">Teacher</span>
        </label>
        <label className="flex items-center">
          <input
            type="radio"
            value="student"
            checked={userRole === "student"}
            onChange={() => handleRoleChange("student")}
          />
          <span className="ml-2">Student</span>
        </label>
      </div>
    </div>
  );
};

export default RoleSelection;
