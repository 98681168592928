import React, { useState, useEffect } from "react";
import GuidedTour from "../../components/GuidedTour";
import { tourStepsMatchingGame } from "./tourStepsMatchingGame";
import { useTour } from "../../context/TourContext";
import { useNavigate } from "react-router-dom";
import BubbleText from "../../components/BubbleText";
import { useTheme } from "../../context/ThemeContext";
import { Collection, getGameCollections, saveGameCollection, updateGameCollection, deleteCollection } from "../../api";
import { useAuth0 } from "@auth0/auth0-react";

/**
 * @fileoverview A matching game component where players connect words with corresponding images.
 * 
 * @component MatchingGame
 * @description An interactive game where players match words with their corresponding images.
 * Includes features like custom word/image pairs, timed modes, and collection management.
 * 
 * @typedef {Object} GameItem
 * @property {string} word - The text to match
 * @property {string} imageUrl - URL or data URI of the matching image
 * 
 * Features:
 * - Custom word-image pair creation
 * - Timed and untimed game modes
 * - Drag-and-drop matching interface
 * - Progress tracking and scoring
 * - Collection saving and management
 * - Guided tour integration
 * 
 * Game Modes:
 * - Timed Mode: Complete matches within a time limit
 * - Practice Mode: No time limit for learning
 */

interface GameItem {
  word: string;
  imageUrl: string;
}

const MatchingGame: React.FC = () => {
  const { isGuidedTourEnabled, isTourRunning, setIsTourRunning } = useTour();
  const [isGameStarted, setIsGameStarted] = useState<boolean>(false);
  const [matches, setMatches] = useState<{ [key: string]: boolean }>({});
  const [currentTourStep, setCurrentTourStep] = useState<number>(0);
  const navigate = useNavigate();
  const { theme } = useTheme();
  const [score, setScore] = useState<number>(0);
  const [isGameCompleted, setIsGameCompleted] = useState<boolean>(false);
  const [timeLeft, setTimeLeft] = useState<number>(60); // Timer state
  const [timerInput, setTimerInput] = useState<number>(60); // User-defined timer length
  const [isTimedMode, setIsTimedMode] = useState<boolean>(true); // State to track if timed mode is enabled
  const [customPairs, setCustomPairs] = useState<Array<{word: string, imageUrl: string}>>([]);
  const [newWord, setNewWord] = useState<string>("");
  const [newImagePreview, setNewImagePreview] = useState<string>("");
  const [randomizedImages, setRandomizedImages] = useState<Array<{word: string, imageUrl: string}>>([]);
  const { getAccessTokenSilently } = useAuth0();
  const [savedCollections, setSavedCollections] = useState<Collection[]>([]);
  const [collectionName, setCollectionName] = useState<string>("");

  // Timer effect
  useEffect(() => {
    if (isGameStarted && timeLeft > 0 && !isGameCompleted) {
      const timer = setInterval(() => {
        setTimeLeft((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(timer);
    } else if (timeLeft === 0) {
      setIsGameCompleted(true);
    }
  }, [isGameStarted, timeLeft, isGameCompleted]);

  // Add this effect to randomize images when the game starts
  useEffect(() => {
    if (isGameStarted) {
      // Create a shuffled copy of customPairs for the images
      const shuffled = [...customPairs].sort(() => Math.random() - 0.5);
      setRandomizedImages(shuffled);
    }
  }, [isGameStarted, customPairs]);

  // Add useEffect to load collections on mount
  useEffect(() => {
    loadSavedCollections().then(collections => {
      if (collections) setSavedCollections(collections);
    });
  }, []);

  const getTextColorClass = () => {
    return theme.backgroundColor.toLowerCase() === "#000000"
      ? "text-white"
      : "text-black";
  };

  const handleTourComplete = () => {
    setIsTourRunning(false);
    localStorage.setItem("tourCompleted_matchingGame", "true");
  };

  const startGame = () => {
    if (isTimedMode) {
      setTimeLeft(timerInput); // Set timer to user-defined length
    } else {
      setTimeLeft(Infinity); // No time limit
    }
    setIsGameStarted(true);
  };

  const handleMatch = (word: string) => {
    if (matches[word] === undefined) {
      setMatches((prev) => ({ ...prev, [word]: true }));
      setScore((prev) => prev + 1);
      
      // Check if this was the last match needed
      if (Object.keys(matches).length + 1 === totalMatches) {
        setIsGameCompleted(true);  // This will stop the timer due to the effect's dependency
      }
    }
  };

  const handleBack = () => {
    navigate("/games");
  };

  const resetGame = () => {
    // Resetting the game state
    setMatches({});
    setScore(0);
    setIsGameCompleted(false);
    setTimeLeft(60); // Reset timer
  };

  const handleWordClick = (word: string) => {
    setSelectedWord(word); // Set the selected word
  };

  const handleImageClick = (index: number) => {
    setSelectedImage(randomizedImages[index].word); // Update to use randomizedImages
    // Check if the selected word matches the image
    if (selectedWord && randomizedImages[index].word === selectedWord) {
      handleMatch(selectedWord);
    } else {
      alert("Incorrect match! Try again.");
    }
  };

  const goBackToSetup = () => {
    resetGame(); // Reset the game state
    setIsGameStarted(false); // Set game started to false to show setup screen
  };

  const calculateLineCoordinates = (
    sourceElement: HTMLElement | null,
    targetElement: HTMLElement | null,
  ) => {
    if (!sourceElement || !targetElement) return null;

    const sourceRect = sourceElement.getBoundingClientRect();
    const targetRect = targetElement.getBoundingClientRect();
    const gameBoard = document.querySelector(".game-board")?.getBoundingClientRect();

    if (!gameBoard) return null;

    // Calculate positions relative to game board
    const x1 = sourceRect.right - gameBoard.left;
    const y1 = sourceRect.top - gameBoard.top + sourceRect.height / 2;
    const x2 = targetRect.left - gameBoard.left;
    const y2 = targetRect.top - gameBoard.top + targetRect.height / 2;

    return { x1, y1, x2, y2 };
  };

  // Add this function to handle image upload
  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setNewImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  // Add this function to handle adding new pairs
  const handleAddPair = () => {
    if (newWord && newImagePreview) {
      setCustomPairs(prev => [...prev, { word: newWord, imageUrl: newImagePreview }]);
      setNewWord("");
      setNewImagePreview("");
    }
  };

  // Add this function to handle removing pairs
  const handleRemovePair = (indexToRemove: number) => {
    setCustomPairs(prev => prev.filter((_, index) => index !== indexToRemove));
  };

  // The totalMatches should now reference customPairs instead
  const totalMatches = customPairs.length; // Total number of matches needed
  const [selectedWord, setSelectedWord] = useState<string | null>(null); // Track selected word
  const [selectedImage, setSelectedImage] = useState<string | null>(null); // Track selected image

  const handleSaveCollection = async () => {
    if (customPairs.length === 0) {
      alert("Please add some pairs before saving!");
      return;
    }
    
    if (!collectionName.trim()) {
      alert("Please enter a name for your collection!");
      return;
    }
    
    try {
      await saveGameCollection(
        collectionName,
        customPairs,
        "matching_game",
        getAccessTokenSilently
      );
      alert("Collection saved successfully!");
      // Refresh the saved collections list
      const collections = await loadSavedCollections();
      if (collections) setSavedCollections(collections);
    } catch (error) {
      console.error("Failed to save collection:", error);
      alert("Failed to save collection. Please try again.");
    }
  };

  const loadSavedCollections = async () => {
    try {
      const collections = await getGameCollections(
        "matching_game",
        getAccessTokenSilently
      );
      // Add UI to display and select from saved collections
      return collections;
    } catch (error) {
      console.error("Failed to load collections:", error);
    }
  };

  return (
    <div className={`page-container ${getTextColorClass()}`}>
      <button
        type="button"
        onClick={handleBack}
        className="absolute left-4 z-40 mt-[20px] rounded border border-black bg-red-500 px-4 py-2 text-white hover:bg-red-600"
      >
        Back to Games
      </button>
      {isGameStarted && ( // Only show buttons if the game has started
        <div className="absolute right-4 z-40 mt-[20px] flex space-x-4">
          <button
            type="button"
            onClick={resetGame}
            className="reset-button mt-5 max-w-[300px] cursor-pointer rounded border border-black bg-yellow-500 p-2.5 text-base font-bold uppercase text-black transition-all duration-300 hover:scale-105 hover:bg-yellow-600 active:scale-95 active:bg-yellow-700"
          >
            Reset Game
          </button>
          <button
            type="button"
            onClick={goBackToSetup}
            className="back-to-setup-button mt-5 max-w-[300px] cursor-pointer rounded border border-black bg-blue-500 p-2.5 text-base font-bold uppercase text-white transition-all duration-300 hover:bg-blue-600"
          >
            Back to Setup
          </button>
        </div>
      )}
      <h1 className="inherit mb-4 text-3xl font-bold">
        <BubbleText>Matching Game</BubbleText>
      </h1>
      {!isGameStarted ? (
        <div className="game-instructions flex flex-col items-center">
          <h2 className="mb-4 text-xl font-bold">Create Your Matching Pairs</h2>
          
          {/* Add pairs section */}
          <div className="mb-4 flex flex-col gap-4">
            <input
              type="text"
              value={newWord}
              onChange={(e) => setNewWord(e.target.value)}
              placeholder="Enter word"
              className="rounded border p-2"
            />
            
            <label htmlFor="image-upload" className="mb-2">Upload Image</label>
            <input
              id="image-upload"
              type="file"
              accept="image/*"
              aria-label="Upload image for matching pair"
              onChange={handleImageUpload}
              className="rounded border p-2"
            />
            
            {newImagePreview && (
              <img 
                src={newImagePreview} 
                alt="Preview" 
                className="h-20 w-20 object-contain"
              />
            )}
            
            <button
              onClick={handleAddPair}
              disabled={!newWord || !newImagePreview}
              className="rounded bg-blue-500 px-4 py-2 text-white hover:bg-blue-600"
            >
              Add Pair
            </button>
          </div>

          {/* Display added pairs */}
          <div className="mb-4 grid grid-cols-2 gap-4">
            {customPairs.map((pair, index) => (
              <div key={index} className="flex items-center gap-2">
                <span>{pair.word}</span>
                <img 
                  src={pair.imageUrl} 
                  alt={pair.word} 
                  className="h-10 w-10 object-contain"
                />
                <button
                  onClick={() => handleRemovePair(index)}
                  className="ml-2 rounded bg-red-500 px-2 py-1 text-white hover:bg-red-600"
                  aria-label="Remove pair"
                >
                  ✕
                </button>
              </div>
            ))}
          </div>

          <p className="inherit mt-2">
            Enter the timer length in seconds for the game:
          </p>
          <input
            type="number"
            value={timerInput}
            onChange={(e) => setTimerInput(Number(e.target.value))}
            className="mt-2 rounded border border-gray-300 p-2"
            placeholder="Enter timer length in seconds"
          />
          <label className="mt-2 flex items-center">
            <input
              type="checkbox"
              checked={!isTimedMode}
              onChange={() => setIsTimedMode((prev) => !prev)} // Toggle timed mode
              className="mr-2" // Add margin to the right of the checkbox
            />
            No Time Limit
          </label>
            <button
              type="button"
              onClick={startGame}
              className="start-button mt-5 max-w-[300px] cursor-pointer rounded border border-black bg-blue-500 p-2.5 text-base font-bold uppercase text-white transition-all duration-300 hover:scale-105 hover:bg-blue-600 active:scale-95"
            >
              Start Game
            </button>
          <div className="mt-4">
            <input
              type="text"
              value={collectionName}
              onChange={(e) => setCollectionName(e.target.value)}
              placeholder="Enter collection name"
              className="mr-2 rounded border p-2"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSaveCollection();
                }
              }}
            />
            <button
              type="button"
              onClick={handleSaveCollection}
              className="mt-4 rounded bg-blue-500 px-4 py-2 text-white hover:bg-blue-600"
            >
              Save Collection
            </button>
          </div>
          <div className="mt-4">
            <select
              title="Select a saved collection"
              onChange={(e) => {
                const collection = savedCollections.find(c => c.collection_id === Number(e.target.value));
                if (collection) {
                  try {
                    // Debug log
                    console.log("Selected collection:", collection);
                    console.log("Collection description:", collection.description);

                    // Parse the double-stringified JSON
                    let items: GameItem[];
                    if (typeof collection.description === "string") {
                      // First parse to get the string array
                      const firstParse = JSON.parse(collection.description);
                      // If it's still a string, parse again
                      items = typeof firstParse === "string" ? JSON.parse(firstParse) : firstParse;
                    } else {
                      items = collection.description as GameItem[];
                    }
                    
                    console.log("Parsed items:", items);

                    // Convert to pairs format
                    const pairs = items
                      .map((item: GameItem) => ({
                        word: item.word,
                        imageUrl: item.imageUrl
                      }))
                      .filter((pair): pair is GameItem => 
                        typeof pair.word === "string" && 
                        typeof pair.imageUrl === "string" && 
                        pair.word.length > 0 && 
                        pair.imageUrl.length > 0
                      );

                    console.log("Extracted pairs:", pairs);
                    
                    if (pairs.length > 0) {
                      setCustomPairs(pairs);
                      setCollectionName(collection.name);
                    } else {
                      throw new Error("No valid pairs found in collection");
                    }
                  } catch (error) {
                    console.error("Error parsing collection data:", error);
                    console.error("Collection data:", collection);
                    alert("Error loading collection: " + (error instanceof Error ? error.message : "Unknown error"));
                  }
                }
              }}
              className="mr-2 rounded border p-2"
            >
              <option value="">Select a saved collection</option>
              {savedCollections.map(collection => (
                <option key={collection.collection_id} value={collection.collection_id}>
                  {collection.name}
                </option>
              ))}
            </select>
            {savedCollections.length > 0 && (
              <div className="mt-2 flex gap-2">
                <button
                  type="button"
                  onClick={async () => {
                    const selected = savedCollections.find(c => c.name === collectionName);
                    if (selected) {
                      if (window.confirm("Are you sure you want to update this collection?")) {
                        try {
                          await updateGameCollection(
                            selected.collection_id,
                            collectionName,
                            customPairs,
                            getAccessTokenSilently
                          );
                          // Update the local state
                          const updatedCollection = {
                            ...selected,
                            name: collectionName,
                            description: JSON.stringify(customPairs)
                          };
                          setSavedCollections(prev => 
                            prev.map(c => c.collection_id === selected.collection_id ? updatedCollection : c)
                          );
                          alert("Collection updated successfully!");
                        } catch (error) {
                          console.error("Failed to update collection:", error);
                          alert("Failed to update collection. Please try again.");
                        }
                      }
                    }
                  }}
                  className="rounded bg-blue-500 px-4 py-2 text-white hover:bg-blue-600"
                >
                  Update Selected
                </button>
                <button
                  type="button"
                  onClick={async () => {
                    const selected = savedCollections.find(c => c.name === collectionName);
                    if (selected && window.confirm("Are you sure you want to delete this collection?")) {
                      await deleteCollection(selected.collection_id, getAccessTokenSilently);
                      const collections = await loadSavedCollections();
                      if (collections) setSavedCollections(collections);
                      setCollectionName("");
                      alert("Collection deleted successfully!");
                    }
                  }}
                  className="rounded bg-red-500 px-4 py-2 text-white hover:bg-red-600"
                >
                  Delete Selected
                </button>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="game-board relative">
          <div className="flex flex-col items-center">
            <p>Time Left: {timeLeft} seconds</p>
            <p>Score: {score}</p>
          </div>
          <div className="flex justify-center">
            <div className="words-section">
              {customPairs.map((pair, index) => (
                <div
                  key={`word-${index}`}
                  onClick={() => handleWordClick(pair.word)}
                  className={`inherit m-2 flex h-24 items-center cursor-pointer ${
                    matches[pair.word] ? "matched" : ""
                  } ${selectedWord === pair.word ? "bg-yellow-300 font-bold" : ""}`}
                  id={`source-${pair.word}`}
                >
                  {pair.word}
                </div>
              ))}
            </div>
            <div className="cards-section">
              {randomizedImages.map((pair, index) => (
                <div
                  key={`image-${index}`}
                  onClick={() => handleImageClick(index)}
                  className={`inherit m-2 cursor-pointer ${
                    selectedImage === pair.word ? "selected" : ""
                  }`}
                  id={`target-${pair.word}`}
                >
                  <img 
                    src={pair.imageUrl} 
                    alt={pair.word}
                    className="h-24 w-24 object-contain"
                  />
                </div>
              ))}
            </div>
          </div>

          <svg className="pointer-events-none absolute left-0 top-0 size-full" style={{ zIndex: 1 }}>
            {Object.entries(matches).map(([word]) => {
              const sourceElement = document.getElementById(`source-${word}`);
              const targetElement = document.getElementById(`target-${word}`);
              const coords = calculateLineCoordinates(sourceElement, targetElement);

              if (!coords) return null;

              // Calculate control points for the curve
              const midX = (coords.x1 + coords.x2) / 3;
              // Extend the end point by 10 pixels
              const extendedX2 = coords.x2;

              return (
                <g key={word}>
                  <path
                    d={`M ${coords.x1} ${coords.y1} 
                        Q ${midX} ${coords.y1}, ${midX} ${(coords.y1 + coords.y2) / 2}
                        Q ${midX} ${coords.y2}, ${extendedX2} ${coords.y2}`}
                    fill="none"
                    stroke="#000"
                    strokeWidth="2"
                    markerEnd="url(#arrowhead)"
                  />
                </g>
              );
            })}
            <defs>
              <marker
                id="arrowhead"
                markerWidth="10"
                markerHeight="7"
                refX="3"
                refY="3.5"
                orient="auto"
              >
                <polygon points="0 0, 10 3.5, 0 7" fill="#000" />
              </marker>
            </defs>
          </svg>
        </div>
      )}

      <GuidedTour
        steps={tourStepsMatchingGame()}
        isRunning={isTourRunning && isGuidedTourEnabled}
        onComplete={handleTourComplete}
        currentStep={currentTourStep}
        onStepChange={setCurrentTourStep}
        tourName="matchingGame"
      />

      {isGameCompleted && (
        <div className="flex flex-col items-center">
          <p>Congratulations! You've completed the game!</p>
          <p>Your total score: {score}</p>
          <button
            type="button"
            onClick={resetGame}
            className="reset-button mt-5 rounded border border-black bg-blue-500 px-4 py-2 text-white hover:bg-blue-600"
          >
            Play Again
          </button>
        </div>
      )}
    </div>
  );
};

export default MatchingGame;
