import React from "react";
import Navbar from "./Navbar";
import CollectionsNavBar from "./CollectionsNavBar";
import Footer from "./Footer";

/**
 * Props for the Layout component
 */
interface LayoutProps {
  /** Child components to be rendered within the layout */
  children: React.ReactNode;
  /** Callback function to initiate the guided tour */
  onStartTour: () => void;
  /** State setter for the current tour name */
  setTourName: React.Dispatch<React.SetStateAction<string>>;
  /** State setter for the current tour step */
  setCurrentTourStep: React.Dispatch<React.SetStateAction<number>>;
  /** State setter for feedback form visibility */
  setShowFeedback: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * Layout component that provides the main structure for the application.
 * Includes navigation bars, content area, and footer.
 * Manages positioning and z-index stacking of major UI elements.
 *
 * @param props - The component props
 * @returns A structured layout with navigation and content areas
 */
const Layout: React.FC<LayoutProps> = ({
  children,
  onStartTour,
  setTourName,
  setCurrentTourStep,
  setShowFeedback,
}) => {
  return (
    <div className="flex min-h-screen flex-col">
      <div className="absolute inset-x-0 top-0 z-50">
        <Navbar
          onStartTour={onStartTour}
          setTourName={setTourName}
          setCurrentTourStep={setCurrentTourStep}
        />
      </div>
      <div className="absolute inset-x-0 top-[200px] z-40">
        <CollectionsNavBar
          setShowFeedback={setShowFeedback}
          onStartTour={onStartTour}
        />
      </div>
      <div className="mt-[264px] grow">{children}</div>
      <Footer />
    </div>
  );
};

export default Layout;
