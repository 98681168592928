import React, { useState, useEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "../../context/ThemeContext";
import { tourStepsFullScreenDisplay } from "./tourStepsFullScreenDisplay";
import GuidedTour from "../../components/GuidedTour";
import FeedbackForm from "../../components/FeedbackForm";
import { useTour } from "../../context/TourContext";
import FeedbackIcon from "../../components/FeedbackIcon";
import Confetti from "react-confetti";

/**
 * @fileoverview A full-screen display component for presenting sequences of items with various display options.
 * 
 * @component FullScreenDisplay
 * @description Displays items in a full-screen mode with configurable timing, animations, and interaction modes.
 * Supports both automatic progression and interactive input modes.
 * 
 * @typedef {Object} CollectionItem
 * @property {string} name - The text content of the item
 * @property {string} [svg] - Optional SVG content for visual representation
 * @property {number} [count] - Optional count value for number sense items
 * 
 * @typedef {Object} FullScreenDisplayProps
 * @property {() => void} onEnterFullScreen - Callback function when entering full-screen mode
 * @property {() => void} onExitFullScreen - Callback function when exiting full-screen mode
 * @property {(show: boolean) => void} setShowFeedback - Function to control feedback form visibility
 * 
 * @typedef {Object} FullScreenDisplayState
 * @property {CollectionItem[]} sequence - Array of items to display
 * @property {number} duration - Total duration of the sequence
 * @property {number} speed - Display speed for each item
 * @property {string} textColor - Color of displayed text
 * @property {boolean} shuffle - Whether to randomize the sequence
 * @property {string} category - Category of items being displayed
 * @property {string} type - Type of display sequence
 * @property {number} answerDisplayTime - Duration to show answers
 * @property {number} timerMinutes - Minutes for timer mode
 * @property {number} timerSeconds - Seconds for timer mode
 * @property {string} stopCondition - Condition to end the sequence
 * @property {boolean} isInteractive - Whether the display requires user interaction
 */

interface CollectionItem {
  name: string;
  svg?: string;
  count?: number;
}

interface FullScreenDisplayProps {
  onEnterFullScreen: () => void;
  onExitFullScreen: () => void;
  setShowFeedback: (show: boolean) => void;
}

interface FullScreenDisplayState {
  sequence: Array<{
    name: string;
    svg?: string;
    count?: number;
  }>;
  duration: number;
  speed: number;
  textColor: string;
  shuffle: boolean;
  category: string;
  type: string;
  answerDisplayTime: number;
  timerMinutes: number;
  timerSeconds: number;
  stopCondition: string;
  isInteractive: boolean;
}

interface SequenceItem {
  name: string;
  isAnswer?: boolean;
  svg?: string;
  count?: number;
}

const FullScreenDisplay: React.FC<FullScreenDisplayProps> = ({
  onEnterFullScreen,
  onExitFullScreen,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  console.log("FullScreenDisplay initialized");
  const {
    sequence,
    speed,
    shuffle,
    category,
    type,
    answerDisplayTime,
    stopCondition: initialStopCondition,
    timerMinutes,
    timerSeconds,
    isInteractive,
  } = location.state as FullScreenDisplayState;
  console.log("Interactive mode:", isInteractive);
  console.log("Session configuration loaded");
  console.log("Session configuration:", {
    category,
    type,
    sequence: sequence.length,
    isInteractive,
  });
  const { theme } = useTheme();
  const [index, setIndex] = useState(0);
  const [shuffledSequence, setShuffledSequence] = useState<SequenceItem[]>([]);
  const [isPaused, setIsPaused] = useState(false);
  const [intervalId, setIntervalId] = useState<number | null>(null);
  const [progress, setProgress] = useState(0);
  const [showAnswer, setShowAnswer] = useState(false);
  const [stopCondition, setStopCondition] = useState(initialStopCondition);
  const [isTourRunning, setIsTourRunning] = useState<boolean>(false);
  const [currentTourStep, setCurrentTourStep] = useState<number>(0);
  const [isFeedbackVisible, setIsFeedbackVisible] = useState(false);
  const { isGuidedTourEnabled } = useTour();
  const [showConfetti, setShowConfetti] = useState(false);
  const [userInput, setUserInput] = useState("");
  const [isCorrect, setIsCorrect] = useState<boolean | null>(null);
  const [startTime, setStartTime] = useState<number | null>(null);
  const [itemStartTime, setItemStartTime] = useState<number | null>(null);
  const [itemTimes, setItemTimes] = useState<number[]>([]);
  const [showResults, setShowResults] = useState(false);
  const [totalTime, setTotalTime] = useState<number>(0);

  // Example usage of isInteractive
  if (isInteractive) {
    console.log("Interactive mode is enabled.");
    // You can add more logic here based on isInteractive
  }

  // Create tour steps without visibility states
  const steps = tourStepsFullScreenDisplay();

  const shuffleArray = (array: CollectionItem[]): CollectionItem[] => {
    return [...array].sort(() => Math.random() - 0.5);
  };

  useEffect(() => {
    console.log("Entering FullScreenDisplay with state:", location.state);
    onEnterFullScreen();

    if (sequence && sequence.length > 0) {
      let newShuffledSequence;
      if (shuffle) {
        console.log("Shuffling sequence...");
        newShuffledSequence = shuffleArray(
          sequence.map((item, index) => ({ ...item, id: index })),
        );
      } else {
        newShuffledSequence = [...sequence];
      }
      console.log("New shuffled sequence:", newShuffledSequence);
      setShuffledSequence(
        newShuffledSequence.map((item, index) => ({
          ...item,
          id: index,
          name: item.name,
          svg: item.svg || "",
          count: item.count || 0,
          isAnswer: false,
        })),
      );
    } else {
      console.error("Sequence is empty or undefined");
    }

    document.documentElement.style.setProperty(
      "--display-text-color",
      theme.displayTextColor || theme.textColor,
    );
    document.documentElement.style.setProperty(
      "--background-color",
      theme.displayBackgroundColor || theme.backgroundColor,
    );

    return () => {
      console.log("Exiting FullScreenDisplay");
      document.documentElement.style.setProperty(
        "--display-text-color",
        theme.textColor,
      );
      document.documentElement.style.setProperty(
        "--background-color",
        theme.backgroundColor,
      );
      onExitFullScreen();
    };
  }, [
    onEnterFullScreen,
    onExitFullScreen,
    sequence,
    shuffle,
    theme,
    location.state,
  ]);

  // Modify the useEffect that handles answer display timing
  useEffect(() => {
    if (shuffledSequence.length > 0 && !isInteractive) {  // Add isInteractive check
      console.log("Current sequence item:", shuffledSequence[index]);
      if (
        category === "Number Sense" ||
        (category === "Math" && shuffledSequence[index].name.includes("+"))
      ) {
        setShowAnswer(false);
        const timer = setTimeout(() => {
          setShowAnswer(true);
        }, speed);
        return () => clearTimeout(timer);
      }
    }
  }, [shuffledSequence, category, speed, index, isInteractive]);  // Add isInteractive to dependencies

  const handleEndSession = useCallback(() => {
    console.log("Ending session...");
    if (stopCondition === "timer") {
      console.log(
        "Session ended due to timer. Redirecting to Your Collections...",
      );
    } else {
      console.log("Session ended. Redirecting to Your Collections...");
      setShowConfetti(true);
    }
    navigate("/your-collections");
    setStopCondition("timer");
  }, [stopCondition, navigate]);

  useEffect(() => {
    if (index === shuffledSequence.length - 1) {
      setShowConfetti(true);
    }
  }, [index, shuffledSequence.length]);

  // Add this function to handle the final item timing
  const getFinalItemDuration = useCallback(() => {
    // If speed is less than 3000ms (3 seconds), use 3000ms for the final item
    return speed < 5000 ? 5000 : speed;
  }, [speed]);

  // Modify the useEffect that handles the interval
  useEffect(() => {
    if (!isInteractive && shuffledSequence.length > 0 && !isPaused) {
      if (
        category === "Number Sense" ||
        (category === "Math" && shuffledSequence[index].name.includes("+"))
      ) {
        const interval = setInterval(() => {
          setIndex((prevIndex) => {
            const newIndex = (prevIndex + 1) % shuffledSequence.length;
            setProgress((newIndex / shuffledSequence.length) * 100);
            setShowAnswer(false);

            // Check if this is the second-to-last item
            if (prevIndex === shuffledSequence.length - 2) {
              // Clear the current interval
              clearInterval(interval);
              // Set up the final item with potentially longer duration
              setTimeout(() => {
                handleEndSession();
              }, getFinalItemDuration() + answerDisplayTime);
            }

            setTimeout(() => {
              setShowAnswer(true);
            }, speed);

            if (newIndex === 0 && stopCondition === "collection") {
              handleEndSession();
            }
            return newIndex;
          });
        }, speed + answerDisplayTime);
        setIntervalId(interval as unknown as number);
        return () => clearInterval(interval);
      } else {
        const interval = setInterval(() => {
          setIndex((prevIndex) => {
            const newIndex = (prevIndex + 1) % shuffledSequence.length;
            setProgress((newIndex / shuffledSequence.length) * 100);

            // Check if this is the second-to-last item
            if (prevIndex === shuffledSequence.length - 2) {
              // Clear the current interval
              clearInterval(interval);
              // Set up the final item with potentially longer duration
              setTimeout(() => {
                handleEndSession();
              }, getFinalItemDuration());
            }

            if (newIndex === 0 && stopCondition === "collection") {
              handleEndSession();
            }
            return newIndex;
          });
        }, speed);
        setIntervalId(interval as unknown as number);
        return () => clearInterval(interval);
      }
    }
  }, [
    shuffledSequence,
    speed,
    isPaused,
    category,
    answerDisplayTime,
    stopCondition,
    handleEndSession,
    getFinalItemDuration,
  ]);

  useEffect(() => {
    if (stopCondition === "timer" && timerMinutes >= 0 && timerSeconds >= 0) {
      const totalTimerMilliseconds = (timerMinutes * 60 + timerSeconds) * 1000;
      console.log(`Timer set for ${totalTimerMilliseconds}ms`);

      const timerEndTime = Date.now() + totalTimerMilliseconds;

      const checkTimer = setInterval(() => {
        const remainingTime = timerEndTime - Date.now();
        if (remainingTime <= 0) {
          console.log("Timer expired, ending session.");
          clearInterval(checkTimer);
          handleEndSession();
        }
      }, 1000);

      return () => {
        clearInterval(checkTimer);
      };
    }
  }, [stopCondition, timerMinutes, timerSeconds, handleEndSession]);

  const handlePauseResume = () => {
    setIsPaused(!isPaused);
    if (isPaused && intervalId) {
      clearInterval(intervalId);
    }
  };

  const handleNext = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIndex((prevIndex) => (prevIndex + 1) % shuffledSequence.length);
  };

  const handlePrevious = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIndex(
      (prevIndex) =>
        (prevIndex - 1 + shuffledSequence.length) % shuffledSequence.length,
    );
  };

  // Modify the handleScreenClick function
  const handleScreenClick = () => {
    if (!isInteractive && (
      category === "Number Sense" ||
      (category === "Math" && shuffledSequence[index].name.includes("+"))
    )) {
      console.log("Toggling answer display. Current state:", !showAnswer);
      setShowAnswer(!showAnswer);
    }
  };

  const handleStartTour = () => {
    console.log("Starting Tour");
    setIsTourRunning(true);
    setCurrentTourStep(0); // Reset to the first step
  };

  const handleTourComplete = () => {
    console.log("Tour completed");
    setIsTourRunning(false); // Reset the tour running state
  };

  // Modify the checkAnswer function
  const checkAnswer = () => {
    let correctAnswer = "";
    const currentItem = shuffledSequence[index];

    if (category === "Number Sense") {
      correctAnswer = currentItem.count?.toString() || "";
    } else if (category === "Math") {
      // For math problems, evaluate the expression to get the correct answer
      if (currentItem.name.includes("+")) {
        const [num1, num2] = currentItem.name.split("+").map(n => parseInt(n.trim()));
        correctAnswer = (num1 + num2).toString();
      } else {
        // For simple numbers, use the name property directly
        correctAnswer = currentItem.name;
      }
    } else {
      correctAnswer = currentItem.name;
    }

    const isAnswerCorrect =
      userInput.trim().toLowerCase() === correctAnswer.toLowerCase();
    setIsCorrect(isAnswerCorrect);

    if (isAnswerCorrect) {
      // Show the answer when correct
      setShowAnswer(true);
      
      // Calculate time taken for this item
      if (itemStartTime) {
        const timeTaken = Date.now() - itemStartTime;
        setItemTimes((prev) => [...prev, timeTaken]);
      }

      // Wait for answer display time before moving to next item
      setTimeout(() => {
        setShowAnswer(false);
        
        // If this is the last item, show results
        if (index === shuffledSequence.length - 1) {
          const endTime = Date.now();
          if (startTime) {
            setTotalTime(endTime - startTime);
          }
          setShowResults(true);
        } else {
          // Reset for next item
          setItemStartTime(Date.now());
          setUserInput("");
          setIsCorrect(null);
          setIndex((prevIndex) => prevIndex + 1);
          setProgress(((index + 1) / shuffledSequence.length) * 100);
        }
      }, answerDisplayTime);
    }
  };

  // Add new effect to handle interactive mode timing
  useEffect(() => {
    if (isInteractive && shuffledSequence.length > 0) {
      // Start timing when component mounts
      setStartTime(Date.now());
      setItemStartTime(Date.now());
    }
  }, [isInteractive, shuffledSequence]);

  // Add Results component
  const ResultsDisplay = () => {
    const formatTime = (ms: number) => {
      const seconds = Math.floor(ms / 1000);
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
    };

    return (
      <div className="fixed inset-0 flex items-center justify-center bg-black/50">
        <div className="rounded-lg bg-white p-8 text-black">
          <h2 className="mb-4 text-2xl font-bold">Session Results</h2>
          <p className="mb-2">Total Time: {formatTime(totalTime)}</p>
          <p className="mb-4">
            Average Time per Item:{" "}
            {formatTime(totalTime / shuffledSequence.length)}
          </p>
          <div className="mb-4 max-h-40 overflow-y-auto">
            {itemTimes.map((time, idx) => (
              <p key={idx}>
                Item {idx + 1}: {formatTime(time)}
              </p>
            ))}
          </div>
          <button
            onClick={handleEndSession}
            className="rounded bg-blue-500 px-4 py-2 text-white hover:bg-blue-600"
          >
            Return to Collections
          </button>
        </div>
      </div>
    );
  };

  // Add this check at the beginning of the component
  if (!shuffledSequence.length) {
    return <div>Loading...</div>;
  }

  console.log("Rendering with index:", index);
  console.log("Current item:", shuffledSequence[index]);

  const getTextClass = (text: string) => {
    const baseClass = "font-[var(--display-font-family)] ";
    if (text.length <= 4) return baseClass + "text-[clamp(25vw,30vw,35vw)]";
    if (text.length <= 7) return baseClass + "text-[clamp(15vw,19vw,23vw)]";
    if (text.length <= 12) return baseClass + "text-[clamp(8vw,12vw,16vw)]";
    if (text.length <= 15) return baseClass + "text-[clamp(5vw,9vw,13vw)]";
    return baseClass + "text-[clamp(2vw,6vw,10vw)]";
  };

  const renderContent = () => {
    const currentItem = shuffledSequence[index];

    if (category === "Number Sense") {
      return (
        <div className="flex size-full flex-col items-center justify-center gap-4">
          {!showAnswer && currentItem.svg && (
            <div className="flex size-full items-center justify-center">
              <img
                src={currentItem.svg}
                alt={currentItem.name}
                style={{ maxWidth: "100%", maxHeight: "100%" }}
                onError={(e) => {
                  console.error("Error loading image:", e);
                  console.log("Attempted SVG source:", currentItem.svg);
                }}
              />
            </div>
          )}

          {showAnswer && (
            <div className="relative">
              <svg viewBox="0 0 200 200" className="size-64">
                <polygon
                  points="100,10 40,180 190,60 10,60 160,180"
                  fill="yellow"
                  stroke="orange"
                  strokeWidth="5"
                />
              </svg>
              <div className="absolute inset-0 flex items-center justify-center">
                <span className="text-6xl font-bold text-orange-600">
                  {currentItem.count}
                </span>
              </div>
            </div>
          )}
        </div>
      );
    } else if (category === "Math" && currentItem.name.includes("+")) {
      return (
        <div className="flex size-full flex-col items-center justify-center gap-4">
          <h1
            className={`max-w-[90vw] break-words text-center leading-tight transition-all duration-300 ${getTextClass(currentItem.name)}`}
          >
            {currentItem.name}
          </h1>

          {showAnswer && (
            <div className="relative">
              <svg viewBox="0 0 200 200" className="size-64">
                <polygon
                  points="100,10 40,180 190,60 10,60 160,180"
                  fill="yellow"
                  stroke="orange"
                  strokeWidth="5"
                />
              </svg>
              <div className="absolute inset-0 flex items-center justify-center">
                <span className="text-6xl font-bold text-orange-600">
                  {currentItem.count}
                </span>
              </div>
            </div>
          )}
        </div>
      );
    } else if (category === "Choose File" || currentItem.svg) {
      return (
        <div className="flex size-full items-center justify-center">
          {currentItem.svg?.startsWith("data:application/pdf") ? (
            <object
              data={currentItem.svg}
              type="application/pdf"
              className="size-full"
              style={{
                width: "100%",
                height: "100vh",
                border: "none",
              }}
            >
              <p>
                Unable to display PDF file.{" "}
                <a href={currentItem.svg} download={currentItem.name}>
                  Download
                </a>{" "}
                instead.
              </p>
            </object>
          ) : (
            <img
              src={currentItem.svg}
              alt={currentItem.name}
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
                objectFit: "contain",
              }}
            />
          )}
        </div>
      );
    } else if (category === "Science" && type === "periodicTable") {
      const [symbol, name, atomicNumber] = currentItem.name.split(" - ");
      return (
        <div className="flex h-full flex-col items-center justify-center">
          <h1 className={`m-0 ${getTextClass(atomicNumber)}`}>
            {atomicNumber}
          </h1>
          <h2 className={`m-0 ${getTextClass(name)}`}>{name}</h2>
          <h3 className={`m-0 ${getTextClass(symbol)}`}>{symbol}</h3>
        </div>
      );
    } else {
      return (
        <h1
          className={`max-w-[90vw] break-words text-center leading-tight transition-all duration-300 ${getTextClass(currentItem.name)}`}
        >
          {currentItem.name}
        </h1>
      );
    }
  };

  console.log("isInteractive:", isInteractive);

  return (
    <div
      className="fullscreen-display relative m-0 flex h-screen w-screen items-center justify-center overflow-hidden p-0 transition-colors duration-300"
      style={{
        color: theme.displayTextColor || theme.textColor,
        backgroundColor: theme.displayBackgroundColor || theme.backgroundColor,
      }}
      onClick={handleScreenClick}
    >
      {/* Confetti effect */}
      {showConfetti && <Confetti />}

      {/* Control buttons in top-left corner */}
      <div className="absolute left-4 top-4 z-50 flex flex-col gap-2">
        <button
          type="button"
          className="bg-custom-red hover:bg-custom-red-dark rounded border border-black px-3 py-1.5 text-sm font-bold text-white transition-colors duration-300"
          onClick={handleEndSession}
        >
          Back
        </button>
        <button
          type="button"
          className="bg-custom-green hover:bg-custom-green-dark rounded border border-black px-3 py-1.5 text-sm font-bold text-white transition-colors duration-300"
          onClick={handlePauseResume}
        >
          {isPaused ? "Resume" : "Pause"}
        </button>
      </div>

      {/* Tour and Feedback buttons in top-right corner */}
      <div className="absolute right-4 top-4 z-50 flex flex-col gap-2">
        {isGuidedTourEnabled && (
          <button
            type="button"
            onClick={handleStartTour}
            className="rounded border border-black bg-blue-500 px-2 py-1 text-xs font-medium text-white transition-colors duration-300 hover:bg-blue-600"
          >
            Start Full Screen Display Tour
          </button>
        )}
        <div className="hover:scale-160 scale-150 transition-transform">
          <FeedbackIcon onClick={() => setIsFeedbackVisible(true)} />
        </div>
      </div>

      {/* Main content */}
      {renderContent()}

      {/* Navigation buttons */}
      <button
        type="button"
        className="w-15 h-15 previous-button absolute left-5 top-1/2 flex -translate-y-1/2 items-center justify-center rounded-full border border-black bg-black/50 pb-[4px] text-5xl text-white transition-colors duration-300 hover:bg-black/70"
        onClick={handlePrevious}
      >
        ←
      </button>
      <button
        type="button"
        className="w-15 h-15 next-button absolute right-5 top-1/2 flex -translate-y-1/2 items-center justify-center rounded-full border border-black bg-black/50 pb-[4px] text-5xl text-white transition-colors duration-300 hover:bg-black/70"
        onClick={handleNext}
      >
        →
      </button>

      {/* Progress indicator */}
      <div className="progress-indicator fixed inset-x-2.5 bottom-2.5 h-2.5 rounded-full border border-black bg-white/30">
        <div
          className="h-full rounded-full border border-black transition-all duration-300 ease-in-out"
          style={{
            width: `${progress}%`,
            backgroundColor: progress === 100 ? "gold" : "green",
          }}
        ></div>
      </div>
      <div
        className="fixed bottom-7 left-2.5 text-xs"
        style={{ color: theme.displayTextColor || theme.textColor }}
      >
        {Math.round(progress)}% Complete
      </div>

      {/* Tour and Feedback components */}
      {isGuidedTourEnabled && (
        <GuidedTour
          steps={steps}
          isRunning={isTourRunning}
          onComplete={handleTourComplete}
          currentStep={currentTourStep}
          onStepChange={setCurrentTourStep}
          tourName="fullScreenDisplay"
        />
      )}

      {isFeedbackVisible && (
        <FeedbackForm onClose={() => setIsFeedbackVisible(false)} />
      )}

      {/* Interactive Mode Elements - Only show when isInteractive is true */}
      {isInteractive && (
        <div className="absolute bottom-20 flex flex-col items-center gap-2">
          <input
            type="text"
            value={userInput}
            onChange={(e) => setUserInput(e.target.value)}
            placeholder="Type your answer"
            className="rounded border border-black px-3 py-1.5 text-sm"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                checkAnswer();
              }
            }}
            autoFocus
          />
          <button
            type="button"
            onClick={checkAnswer}
            className="bg-custom-blue hover:bg-custom-blue-dark rounded border border-black px-3 py-1.5 text-sm font-bold text-white transition-colors duration-300"
          >
            Submit Answer
          </button>
          {isCorrect !== null && (
            <div>
              {isCorrect ? (
                <p className="text-green-500">Correct!</p>
              ) : (
                <p className="text-red-500">Incorrect, try again!</p>
              )}
            </div>
          )}
        </div>
      )}

      {showResults && <ResultsDisplay />}
    </div>
  );
};

export default FullScreenDisplay;
