import React, { useRef, useEffect } from "react";

/**
 * Represents an item in the shopping cart
 */
interface CartItem {
  /** Name of the item */
  name: string;
  /** Price of the item in currency units */
  price: number;
  /** Quantity of the item in cart */
  quantity: number;
}

/**
 * Props for the Cart component
 */
interface CartProps {
  /** Controls whether the cart modal is visible */
  isOpen: boolean;
  /** Callback function to close the cart modal */
  onClose: () => void;
  /** Array of items currently in the cart */
  items: CartItem[];
  /** Optional callback to remove an item from the cart */
  onRemoveItem?: (index: number) => void;
  /** Optional callback to update the quantity of an item */
  onUpdateQuantity?: (index: number, quantity: number) => void;
}

/**
 * Cart component that displays a modal with the current shopping cart contents.
 * Allows users to view items, update quantities, remove items, and see the total price.
 * Closes when clicking outside the modal.
 *
 * @param props - The component props
 * @returns A modal displaying the shopping cart contents or null if closed
 */
const Cart: React.FC<CartProps> = ({
  isOpen,
  onClose,
  items,
  onRemoveItem,
  onUpdateQuantity,
}) => {
  console.log("Cart items:", items); // Log the items in the cart
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  const total = items.reduce(
    (sum, item) => sum + item.price * item.quantity,
    0,
  );

  return (
    <div className="fixed inset-0 z-[100] flex items-center justify-center bg-black bg-opacity-50">
      <div
        ref={modalRef}
        className={`relative w-full max-w-md rounded-lg bg-white p-6 text-black shadow-xl`}
      >
        <button
          type="button"
          onClick={onClose}
          className="absolute right-4 top-4 text-2xl"
        >
          ×
        </button>

        <h2 className="mb-4 text-2xl font-bold">Your Cart</h2>

        {items.length === 0 ? (
          <p className="text-center">Your cart is empty</p>
        ) : (
          <>
            <div className="mb-4 max-h-[60vh] overflow-y-auto">
              {items.map((item, index) => (
                <div
                  key={index}
                  className={`mb-2 flex items-center justify-between rounded-lg bg-gray-100 p-3`}
                >
                  <div>
                    <h3 className="font-semibold">{item.name}</h3>
                    <p>
                      ${item.price.toFixed(2)} × {item.quantity}
                    </p>
                  </div>
                  <div className="flex items-center gap-3">
                    <div className="flex items-center gap-2">
                      <button
                        type="button"
                        onClick={() =>
                          onUpdateQuantity?.(
                            index,
                            Math.max(1, item.quantity - 1),
                          )
                        }
                        className="rounded-full bg-gray-200 px-2 py-1 text-black hover:bg-gray-300"
                      >
                        -
                      </button>
                      <span className="w-8 text-center">{item.quantity}</span>
                      <button
                        type="button"
                        onClick={() =>
                          onUpdateQuantity?.(index, item.quantity + 1)
                        }
                        className="rounded-full bg-gray-200 px-2 py-1 text-black hover:bg-gray-300"
                      >
                        +
                      </button>
                    </div>
                    {onRemoveItem && (
                      <button
                        type="button"
                        onClick={() => onRemoveItem(index)}
                        className="text-red-500 hover:text-red-700"
                      >
                        Remove
                      </button>
                    )}
                  </div>
                </div>
              ))}
            </div>

            <div className="border-t pt-4">
              <div className="mb-4 flex justify-between text-xl font-bold">
                <span>Total:</span>
                <span>${total.toFixed(2)}</span>
              </div>
              <button
                type="button"
                className="w-full rounded-lg bg-blue-500 px-6 py-3 text-lg font-bold text-white transition-all hover:bg-blue-600 active:bg-blue-700"
              >
                Proceed to Checkout
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Cart;
