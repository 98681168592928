/**
 * Mapping of collection categories to their corresponding Tailwind CSS background colors.
 * Used for consistent visual categorization across the application.
 */
export const categoryColors = {
  "All Collections": "bg-blue-500",
  Math: "bg-blue-500",
  "Language Arts": "bg-green-500",
  "Number Sense": "bg-purple-500",
  Science: "bg-yellow-500",
  Nursing: "bg-red-500",
} as const;
